import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import * as Sentry from '@sentry/react';

function ErrorPage() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ fontSize: 96 }}>:(</div>
      <h2>Uh oh.</h2>
      <h4>
        Something went wrong on our end. Our development team has been sent an
        error report.
      </h4>
      <Box width={200}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              component={Link}
              variant='outlined'
              color='secondary'
              to='/'
            >
              Start over
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant='outlined'
              color='secondary'
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default function ErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
