import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import ViewToggle from '../ViewToggle';
import HopthruLogo from '../HopthruLogo';
import UserMenuButton from '../UserMenuButton';
import ExpandMenuButton from '../ExpandMenuButton';
import NotificationButton from '../NotificationButton';

// context
import { useSelector } from 'react-redux';
import { selectAgency } from '../../store/slices/userSlice';

export default function Header() {
  const history = useHistory();

  // global
  const { isCleanseOnly, showExpand } = useSelector(selectAgency);

  let page = 'analyze';
  if (history.location.pathname.includes('settings')) {
    page = 'settings';
  } else if (history.location.pathname.includes('insights')) {
    page = 'insights';
  }

  const leftHandItems = [];
  if (page === 'settings') {
    if (!isCleanseOnly) {
      leftHandItems.push(
        <IconButton
          aria-label='Exit settings'
          component={Link}
          key='exit-settings-icon-button'
          title='Exit settings'
          to='/'
          size='small'
          sx={{
            color: 'secondary.main',
            mr: 1,
          }}
        >
          <ArrowBack />
        </IconButton>
      );
    }
    leftHandItems.push(
      <Typography
        key='settings-title-label'
        variant='h6'
        sx={{
          color: 'secondary.main',
          whiteSpace: 'nowrap',
          textDecoration: 'none',
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        Settings
      </Typography>
    );
  } else {
    leftHandItems.push(<HopthruLogo key='hopthru-main-logo' />);
  }

  const rightHandItems = [];
  if (showExpand && page === 'analyze') {
    rightHandItems.push(<ExpandMenuButton key='expanded-data-toggle-button' />);
  }
  rightHandItems.push(
    <NotificationButton key='notification-menu-toggle-button' />,
    <UserMenuButton key='user-menu-toggle-button' />
  );

  return (
    <AppBar
      position='fixed'
      sx={(theme) => ({
        height: theme.spacing(6.5),
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.tertiary.main}`,
        zIndex: theme.zIndex.drawer - 1,
        justifyContent: 'center',
      })}
    >
      <Toolbar
        sx={{
          py: 0,
          px: { xs: 1.5 },
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          flexGrow={1}
          flexBasis={0}
        >
          {leftHandItems}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexGrow={3}
          flexBasis={0}
        >
          {page !== 'settings' && <ViewToggle />}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          flexGrow={1}
          flexBasis={0}
        >
          {rightHandItems}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
