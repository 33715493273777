import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface HeaderMenuButtonProps extends IconButtonProps {
  isActive?: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<HeaderMenuButtonProps>(({ theme, isActive }) => ({
  border: '1.5px solid #DADCE0',
  borderRadius: 50,
  fontSize: '2rem',
  height: theme.spacing(5.25),
  marginLeft: theme.spacing(1),
  padding: theme.spacing(1),
  width: theme.spacing(5.25),
  ...(isActive && {
    backgroundColor: '#e4e4e4',
    border: '1.5px solid #202124',
  }),
}));

export default function HeaderMenuButton({
  isActive,
  className,
  ...props
}: HeaderMenuButtonProps) {
  return <StyledIconButton {...props} size='large' className={className} />;
}
