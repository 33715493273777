import { signOut } from '../slices/userSlice';
import { api } from '../slices/apiSlice';

const USER_PROPERTY_MAP = new Map([
  ['access_token', 'accessToken'],
  ['refresh_token', 'refreshToken'],
  ['first_name', 'firstName'],
  ['last_name', 'lastName'],
  ['intercom_hash', 'intercomHash'],
  ['username', 'username'],
  ['user_id', 'userId'],
  ['email', 'email'],
  ['is_staff', 'isStaff'],
]);

const USER_JSON_PROPERTY_MAP = new Map([
  ['user_role', 'userRole'],
  ['user_roles', 'userRoles'],
]);

// Middleware for persisting user state to local/session storage
const userMiddleware = (store) => (next) => (action) => {
  const nextState = next(action);

  if (signOut.match(action)) {
    for (let property of USER_PROPERTY_MAP.keys()) {
      localStorage.removeItem(property);
      sessionStorage.removeItem(property);
    }
    for (let property of USER_JSON_PROPERTY_MAP.keys()) {
      localStorage.removeItem(property);
      sessionStorage.removeItem(property);
    }
    store.dispatch(api.util.invalidateTags(['User']));
  } else if (action.type && action.type.startsWith('user/')) {
    const userState = store.getState().user;
    for (let property of USER_PROPERTY_MAP.keys()) {
      if (userState.hasOwnProperty(USER_PROPERTY_MAP.get(property))) {
        localStorage.setItem(
          property,
          userState[USER_PROPERTY_MAP.get(property)]
        );
      }
    }
    for (let property of USER_JSON_PROPERTY_MAP.keys()) {
      if (userState.hasOwnProperty(USER_JSON_PROPERTY_MAP.get(property))) {
        const jsonProperty = userState[USER_JSON_PROPERTY_MAP.get(property)];
        localStorage.setItem(property, JSON.stringify(jsonProperty));
      }
    }
  }

  return nextState;
};

export default userMiddleware;
