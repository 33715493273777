import React, { useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { withRouter } from 'react-router-dom';

// logo
import logo from '../../assets/hopthru.svg';

// context
import { setPassword } from '../../store/effects/user';
import { updateIsAuthenticated } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

function Reset(props) {
  // global
  const dispatch = useDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [newPassword, setNewPassword] = useState('');
  var [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  let params = queryString.parse(props.history.location.search);

  function onEnterPressed() {
    if (newPassword !== confirmNewPassword) {
      setError('Password confimation does not match');
    } else {
      setError(false);
      dispatch(
        setPassword(
          params.token,
          newPassword,
          false,
          setIsLoading,
          setError,
          () => dispatch(updateIsAuthenticated(true))
        )
      );
    }
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: {
            xs: '50%',
            lg: '100%',
          },
        }}
      >
        <Box
          sx={{
            width: 400,
            height: 562,
            borderRadius: 2.5,
            py: 4,
            px: 2.5,
            border: 'solid 1.5px #dadce0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component='img'
            src={logo}
            alt='logo'
            sx={{ width: 211, margin: 'auto' }}
          />
          <Typography
            color='secondary'
            variant='h2'
            style={{ margin: 'auto', marginTop: 16 }}
          >
            Set a new password
          </Typography>
          <Typography color='secondary'>Password</Typography>
          <TextField
            id='password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onEnterPressed();
                ev.preventDefault();
              }
            }}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    size='large'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography color='secondary' style={{ marginTop: 4 }}>
            Confirm password
          </Typography>
          <TextField
            id='confirmPassword'
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                onEnterPressed();
                ev.preventDefault();
              }
            }}
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    size='large'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Fade in={error}>
            <Typography color='error' sx={{ mt: 0.5, textAlign: 'center' }}>
              {error}
            </Typography>
          </Fade>
          <Box sx={{ w: 1, textAlign: 'center', mt: 4, alignItems: 'center' }}>
            <Button
              style={{ width: '100%', height: 50 }}
              disabled={newPassword.length === 0}
              onClick={() => onEnterPressed()}
              variant='contained'
              color='secondary'
              sx={{ my: 1 }}
            >
              Set password
            </Button>
            {isLoading && (
              <CircularProgress
                size={26}
                color='secondary'
                style={{ marginTop: 16 }}
              />
            )}
          </Box>
        </Box>
        <Typography
          color='secondary'
          sx={{
            mt: 4,
            whiteSpace: 'nowrap',
            position: {
              xs: 'static',
              md: 'absolute',
            },
            bottom: {
              md: 2,
            },
          }}
        >
          © 2025 Hopthru, Inc. All rights reserved.
        </Typography>
      </Box>
    </Grid>
  );
}

export default withRouter(Reset);
