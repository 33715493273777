import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { postEvent } from '../../utils/api';
import history from '../../utils/history';
import * as Sentry from '@sentry/react';
import { filterEmptyProperties } from '../../utils/utils';
import {
  getAgencyFromRole,
  getActiveUserRole,
  persistSession,
} from '../../utils/user';
import { RootState } from '..';
import { User } from '../types';
import tracking from '../../utils/tracking';

import {
  signOut as signOutAction,
  updateUser,
  updateAccessToken,
  updateRefreshToken,
} from '../slices/userSlice';
import { api } from '../slices/apiSlice';

async function handleResponse(
  response: Response,
  onBadResponse?: (response: Response) => void
) {
  const text = await response.text();
  const data = text && JSON.parse(text);
  if (!response.ok) {
    onBadResponse && onBadResponse(response);
    const error = (data && data.message) || response.statusText;
    throw new Error(error);
  }
  return data;
}

function fetchUser(
  onFulfilled?: (user: User) => void,
  onRejected?: (e: any) => void
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const accessToken = state.user.accessToken;

    const response = dispatch(api.endpoints.getMe.initiate());
    try {
      const { data: user, error } = await response;

      if (error) {
        throw error;
      }

      if (user) {
        const userRoles = user.user_roles;
        const userState = filterEmptyProperties({
          email: user.email,
          firstName: user.first_name,
          intercomHash: user.intercom_hash,
          isAuthenticated: !!accessToken,
          lastName: user.last_name,
          userId: user.user_id,
          userRoles,
          username: user.username,
          isStaff: user.is_staff,
        });

        if (userRoles) {
          const userRole = getActiveUserRole(userRoles);

          if (userRole && userRole.agency) {
            persistSession(JSON.stringify(userRole), userRole.agency.id);
            userState.userRole = userRole || {};
            userState.agency = getAgencyFromRole(userRole);
          }
        }

        dispatch(updateUser(userState));
        onFulfilled && onFulfilled(user);
      }
    } catch (e) {
      onRejected && onRejected(e);
      Sentry.captureException(e);
    }

    response.unsubscribe?.();
  };
}

function loginUser(
  login: string | null,
  password: string | null,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: any) => void
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    setError(false);
    setIsLoading(true);

    if (!!login && !!password) {
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: login, password: password }),
      };

      const onRejected = (response: Response) => {
        if (response.status === 401) {
          setError(true);
          setIsLoading(false);
        }
        tracking.event('analyze-login-failed');
        postEvent('login_fail', { email: login });
      };

      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/token/`,
          requestOptions
        );
        const token = await handleResponse(response, onRejected);
        if (token) {
          dispatch(updateAccessToken(token.access));
          dispatch(updateRefreshToken(token.refresh));
          dispatch(
            fetchUser(() => {
              tracking.event('analyze-login-success');
              postEvent('login_success');
              setError(false);
              setIsLoading(false);
            }, onRejected)
          );
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    } else {
      setError(true);
      setIsLoading(false);
    }
  };
}

async function sendResetPassword(
  email: string,
  setIsLoading: (isLoading: boolean) => void,
  setError?: (error: any) => void
) {
  if (email) {
    setIsLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    };
    let response = await fetch(
      import.meta.env.VITE_BACKEND_URL + '/api/password_reset/',
      requestOptions
    );
    let data = await response.json();
    setIsLoading(false);
    if (response.ok) {
      return data;
    } else {
      if (setError) {
        if (data.message) setError(data.message);
        else setError(response.statusText);
      }
    }
  }
}

function setPassword(
  token: string,
  password: string,
  register: boolean,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: any) => void,
  onSuccess?: () => void
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    setError(false);
    setIsLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        password,
        register,
      }),
    };

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/password_reset/confirm/`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        dispatch(updateAccessToken(data.access));
        dispatch(updateRefreshToken(data.refresh));
        setIsLoading(false);
        onSuccess?.();
      } else {
        if (data.password) {
          setError(data.password);
        } else if (response.status === 404) {
          if (data.status === 'expired') {
            setError(
              'The link you clicked is no longer active. Please contact an admin for assistance.'
            );
          } else if (data.status === 'notfound') {
            setError(
              "We couldn't find an invitation associated with the link you clicked. Please login if you already have an account or contact an admin for assistance."
            );
          }
        } else {
          setError(response.statusText);
        }
        setIsLoading(false);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}

function refreshToken(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const refreshToken = state.user.refreshToken;
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    };

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/token/refresh/`,
        options
      );
      const token = await handleResponse(response, (response) => {
        if (response.status === 401) {
          dispatch(signOut());
        }
      });
      if (token) {
        dispatch(updateAccessToken(token.access));
        dispatch(updateRefreshToken(token.refresh));
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}

function signOut(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    tracking.event('analyze-logout-success');
    postEvent('logout_success');
    Sentry.setUser(null);
    dispatch(signOutAction());
    history.push('/login');
  };
}

export {
  loginUser,
  sendResetPassword,
  setPassword,
  signOut,
  refreshToken,
  fetchUser,
};
