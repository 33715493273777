import React from 'react';
import { useHistory } from 'react-router-dom';
import HopthruLogo from '../HopthruLogo';
import { AppBar, Box, Divider, Toolbar } from '@mui/material';
import PrivacySelectorButton from '../PrivacySelectorButton';
import FavoriteButton from '../FavoriteButton';
import UserMenuButton from '../UserMenuButton';
import NotificationButton from '../NotificationButton';
import ViewModeToggleButton from '../ViewModeToggleButton';
import ViewName from '../ViewName';

export default function HeaderV2() {
  const history = useHistory();

  const isSettings = history.location.pathname.includes('settings');

  return (
    <AppBar
      position='relative'
      sx={(theme) => ({
        color: 'primary.main',
        backgroundColor: 'tertiary.light',
        height: theme.spacing(5),
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderColor: 'tertiary.main',
      })}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: {
            xs: 40,
          },
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          flexGrow={1}
          minWidth={0}
        >
          <HopthruLogo size={32} />
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              marginLeft: 2,
            }}
          />
          {isSettings && (
            <Box
              sx={{
                color: 'secondary.main',
                fontWeight: 500,
                fontSize: '1rem',
                marginLeft: 2,
              }}
            >
              Settings
            </Box>
          )}
          {!isSettings && (
            <>
              <FavoriteButton />
              <PrivacySelectorButton />
              <Box mx={1} width='100%' minWidth={0}>
                <ViewName />
              </Box>
            </>
          )}
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          flexGrow={1}
        >
          {!isSettings && <ViewModeToggleButton />}
          <NotificationButton useV2 />
          <UserMenuButton useV2 />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
