import { createSlice, createSelector, current } from '@reduxjs/toolkit';
import { getAgencyFromRole, getActiveUserRole } from '../../utils/user';
import { filterEmptyProperties } from '../../utils/utils';
import { UserRole } from '../types';
import { RootState } from '..';

export interface UserState {
  accessToken?: string;
  email?: string;
  firstName?: string;
  intercomHash?: string;
  isAuthenticated: boolean;
  lastName?: string;
  refreshToken?: string;
  userId?: string;
  userRole?: UserRole;
  userRoles?: UserRole[];
  isStaff?: boolean;
  agency?: {
    agencyKey: string;
    agencyName: string;
    agencyId: string;
    agencyLatitude: number;
    agencyLongitude: number;
    agencyDataStartDate: string;
    agencyDataEndDate?: string;
    agencyDataFeedDaysTrailing: number;
    agencyTimeZone: string;
    displayDistanceType: 'miles' | 'kilometers';
    internalName: string;
  } & FeatureFlags;
}

export const emptyAgency = {
  agencyKey: '',
  agencyName: '',
  agencyId: '',
  agencyLatitude: 0,
  agencyLongitude: 0,
  agencyDataStartDate: '',
  agencyDataEndDate: '',
  agencyDataFeedDaysTrailing: 0,
  agencyTimeZone: '',
  displayDistanceType: 'miles' as const,
  internalName: '',
  showOffs: false,
  showDirection: false,
  showLoad: false,
  showPDT: false,
  showTrips: false,
  showInsights: false,
  showAtypicalDays: false,
  showMaxload: false,
  showTotalLoad: false,
  showActivity: false,
  showBPRH: false,
  showExpand: false,
  useStopCode: false,
  isCleanseOnly: false,
};

function getLocalStorageState(): UserState {
  const userRoles = localStorage.getItem('user_roles');
  const userState = filterEmptyProperties({
    accessToken: localStorage.getItem('access_token'),
    email: localStorage.getItem('email'),
    firstName: localStorage.getItem('first_name'),
    intercomHash: localStorage.getItem('intercom_hash'),
    lastName: localStorage.getItem('last_name'),
    refreshToken: localStorage.getItem('refresh_token'),
    userId: localStorage.getItem('user_id'),
    userRoles,
    username: localStorage.getItem('username'),
    isStaff: localStorage.getItem('is_staff'),
  });

  if (userRoles) {
    const userRole = getActiveUserRole(JSON.parse(userRoles));

    if (userRole) {
      let agency = {
        agencyName: null,
        agencyId: null,
        agencyLatitude: 37.7749,
        agencyLongitude: -122.4194,
        agencyDataStartDate: null,
        agencyDataEndDate: null,
        agencyDataFeedDaysTrailing: null,
        agencyTimeZone: null,
        displayDistanceType: 'miles',
        internalName: '',
        showOffs: null,
        showDirection: null,
        showLoad: null,
        showPDT: null,
        showTrips: null,
        showInsights: null,
        showAtypicalDays: null,
        showMaxload: null,
        showTotalLoad: null,
        showActivity: null,
        showBPRH: null,
        showExpand: null,
        useStopCode: null,
        isCleanseOnly: null,
      };
      agency = Object.assign(agency, getAgencyFromRole(userRole));

      userState.userRole = userRole;
      userState.agency = agency;
    }
  }

  return {
    ...userState,
    isAuthenticated: !!localStorage.getItem('access_token'),
  };
}

export const initialState: UserState = getLocalStorageState();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      return {
        ...current(state),
        ...action.payload,
      };
    },
    updateUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    updateAgency: (state, action) => {
      state.agency = {
        ...state.agency,
        ...action.payload,
      };
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    updateIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    signOut: () => ({ isAuthenticated: false }),
  },
});

// ACTIONS
export const {
  signOut,
  updateUser,
  updateUserRole,
  updateAgency,
  updateAccessToken,
  updateRefreshToken,
  updateIsAuthenticated,
} = userSlice.actions;

// SELECTORS
export const selectAccessToken = (state: RootState) => state.user.accessToken;
export const selectAgency = (state: RootState) =>
  state.user.agency || emptyAgency;
export const selectFirstName = (state: RootState) => state.user.firstName;
export const selectLastName = (state: RootState) => state.user.lastName;
export const selectUser = (state: RootState) => state.user;
export const selectUserRole = (state: RootState) => state.user.userRole || {};
export const selectFullName = createSelector(
  [selectFirstName, selectLastName],
  (firstName, lastName) => `${firstName} ${lastName}`.trim()
);

// REDUCER
export default userSlice.reducer;
