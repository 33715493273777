import React from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledIconButtonProps extends IconButtonProps {
  isActive?: boolean;
  variant?: 'light' | 'dark';
  align?: 'left' | 'right';
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'isActive' && prop !== 'variant' && prop !== 'align',
})<StyledIconButtonProps>(({ theme, isActive, variant, align }) => ({
  border: 'none',
  borderRadius: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: '1.5rem',
  height: theme.spacing(3.5),
  width: theme.spacing(3.5),
  '&.Mui-disabled': {
    color: theme.palette.primary.main,
  },
  '&.Mui-focusVisible': {
    outlineColor: theme.palette.primary.main,
  },
  ...(variant === 'dark' && {
    color: theme.palette.secondary.main,
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    '&.Mui-focusVisible': {
      outlineColor: theme.palette.secondary.main,
    },
  }),
  ...(isActive && {
    backgroundColor: 'rgba(0,0,0,0.4)',
  }),
  marginRight: align === 'left' ? theme.spacing(1.5) : 0,
  marginLeft: align === 'right' ? theme.spacing(1.5) : 0,
}));

type HeaderMenuButtonV2Props = {
  title: string;
  isActive?: boolean;
  className?: string;
  variant?: 'light' | 'dark';
  align?: 'left' | 'right';
} & IconButtonProps;

export default function HeaderMenuButtonV2({
  isActive,
  className,
  variant = 'light',
  title,
  align = 'right',
  ...props
}: HeaderMenuButtonV2Props) {
  return (
    <Tooltip title={title}>
      <span>
        <StyledIconButton
          {...props}
          aria-label={title}
          className={className}
          isActive={isActive}
          variant={variant}
          align={align}
          size='large'
        />
      </span>
    </Tooltip>
  );
}
