import {
  InsightActivity,
  Insight,
  InsightFilter,
  InsightGrouping,
  InsightType,
  InsightFilterType,
  InsightEntityFilter,
  InsightFilterInclusion,
  Legend,
} from '../pages/insights/types';
import { DEFAULT_ROUTES_LAYER, DEFAULT_STOPS_LAYER } from './views';
import {
  LayerGroup,
  Subscription,
  SubscriptionFrequency,
} from '../store/types';
import { store } from '../store';
import moment from 'moment-timezone';
import {
  RelativePeriod,
  getDatesForRelativePeriod,
  getRelativePeriodForDates,
} from './dates';
import { ViewType } from './views';
import tracking from './tracking';

const getNameForInsightType = (type: InsightType) => {
  let name = '';
  switch (type) {
    case InsightType.SEGMENTS:
    case InsightType.STOPS:
      name = 'Stops';
      break;
    case InsightType.TRIPS:
      name = 'Trips';
      break;
    case InsightType.ROUTES:
      name = 'Routes';
      break;
    case InsightType.SYSTEM:
      name = 'System';
      break;
    case InsightType.LAYERS:
      name = 'Layers';
      break;
  }
  return name;
};

const getNameForInsightResultKey = (key?: string) => {
  let name = '';
  switch (key) {
    case 'avgOns':
      name = 'Average daily boardings';
      break;
    case 'sumOns':
      name = 'Total boardings';
      break;
    case 'avgOffs':
      name = 'Average daily alightings';
      break;
    case 'sumOffs':
      name = 'Total alightings';
      break;
    case 'avgActivity':
      name = 'Average daily activity';
      break;
    case 'sumActivity':
      name = 'Total activity';
      break;
    case 'avgLoad':
      name = 'Average load';
      break;
    case 'maxLoad':
      name = 'Max load';
      break;
    case 'avgMaxLoad':
      name = 'Average daily max load';
      break;
    case 'boardingsPerRevenueHour':
      name = 'Boardings per revenue hour';
      break;
    case 'avgPassengerDistanceTravelled':
      name = 'Average passenger distance travelled';
      break;
    case 'sumPassengerDistanceTravelled':
      name = 'Total passenger distance travelled';
      break;
    case 'hour':
      name = 'Hour';
      break;
    case 'week':
      name = 'Week';
      break;
    case 'day':
      name = 'Service date';
      break;
    case 'month':
      name = 'Month';
      break;
    case 'year':
      name = 'Year';
      break;
    case 'dayofweek':
    case 'weekdaysatsun':
      name = 'Day of week';
      break;
    case 'timerange':
      name = 'Time range';
      break;
    case 'gtfsStopId':
      name = 'Stop ID';
      break;
    case 'gtfsStopCode':
      name = 'Stop code';
      break;
    case 'gtfsStopName':
      name = 'Stop name';
      break;
    case 'gtfsRouteId':
      name = 'Route ID';
      break;
    case 'gtfsRouteShortName':
      name = 'Route name';
      break;
    case 'gtfsStopLat':
      name = 'Latitude';
      break;
    case 'gtfsStopLon':
      name = 'Longitude';
      break;
    case 'direction':
      name = 'Direction';
      break;
    case 'startTime':
      name = 'Start time';
      break;
    case 'endTime':
      name = 'End time';
      break;
    case 'length':
      name = 'Length';
      break;
    case 'layerName':
      name = 'Layer name';
      break;
  }
  return name;
};

const partitionByType = (insights: Insight[]) => {
  return insights.reduce(
    (insightsByType, insight) => {
      if (!insightsByType[insight.insightType]) {
        insightsByType[insight.insightType] = [];
      }
      insightsByType[insight.insightType]!.push(insight);
      return insightsByType;
    },
    {} as { [key in InsightType]?: Insight[] }
  );
};

const partitionByCreator = (insights: Insight[], userId: string) => {
  return insights.reduce(
    ([mine, others], insight) => {
      return insight.creatorId === userId
        ? [[...mine, insight], others]
        : [mine, [...others, insight]];
    },
    [[] as Insight[], [] as Insight[]]
  );
};

const getValidInsightActivityColumns = (
  insight: Insight,
  flags: Partial<FeatureFlags>
) => {
  const validColumns = [];

  validColumns.push('avgOns', 'sumOns');
  if (flags.showOffs) {
    validColumns.push('avgOffs', 'sumOffs');
  }
  if (flags.showActivity) {
    validColumns.push('avgActivity', 'sumActivity');
  }
  if (
    flags.showBPRH &&
    insight.insightType !== InsightType.STOPS &&
    insight.insightType !== InsightType.LAYERS
  ) {
    validColumns.push('boardingsPerRevenueHour');
  }
  if (flags.showLoad && insight.insightType !== InsightType.STOPS) {
    validColumns.push('avgLoad');
    validColumns.push('avgMaxLoad');
  }
  if (
    flags.showMaxload &&
    insight.insightType !== InsightType.STOPS &&
    insight.insightType !== InsightType.LAYERS
  ) {
    validColumns.push('maxLoad');
  }
  if (
    flags.showPDT &&
    insight.insightType !== InsightType.STOPS &&
    insight.insightType !== InsightType.LAYERS
  ) {
    validColumns.push(
      'avgPassengerDistanceTravelled',
      'sumPassengerDistanceTravelled'
    );
  }

  return validColumns;
};

const getInsightGroupingColumns = (insight: Insight): string[] => {
  const groupColumns = [];
  if (insight.groupBy) {
    groupColumns.push(insight.groupBy);
    if (insight.groupBy === InsightGrouping.MONTH) {
      groupColumns.push(InsightGrouping.YEAR);
    }
  }

  return groupColumns;
};

const getRequiredInsightColumns = (insight: Insight): string[] => {
  const requiredColumns = getInsightGroupingColumns(insight);

  switch (insight.insightType) {
    case InsightType.ROUTES:
      requiredColumns.push('gtfsRouteShortName');
      break;
    case InsightType.STOPS:
      requiredColumns.push('gtfsStopName');
      break;
    case InsightType.TRIPS:
      requiredColumns.push('startTime', 'direction');
      break;
    case InsightType.LAYERS:
      requiredColumns.push('layerName');
      break;
    case InsightType.SYSTEM:
    default:
      break;
  }

  return requiredColumns;
};

const getValidInsightEntityColumns = (insight: Insight): string[] => {
  const validColumns = getRequiredInsightColumns(insight);

  switch (insight.insightType) {
    case InsightType.ROUTES:
      validColumns.push('gtfsRouteId');
      break;
    case InsightType.STOPS:
      validColumns.push(
        'gtfsStopId',
        'gtfsStopCode',
        'gtfsStopLat',
        'gtfsStopLon'
      );
      break;
    case InsightType.TRIPS:
      validColumns.push(
        'endTime',
        'gtfsRouteId',
        'gtfsRouteShortName',
        'length'
      );
      break;
    case InsightType.SYSTEM:
    default:
      break;
  }

  return validColumns;
};

const filterValidInsightColumns = (
  columns: string[] = [],
  insight: Insight,
  flags: Partial<FeatureFlags>
) => {
  const validColumns = [
    ...getValidInsightEntityColumns(insight),
    ...getValidInsightActivityColumns(insight, flags),
  ];
  return columns.filter((column) => validColumns.includes(column));
};

const getResultDateRange = (
  insight: Insight,
  activity: InsightActivity,
  max?: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();

  const dateRange = insight.dateRanges?.[0];
  if (
    !insight.groupBy ||
    insight.groupBy === InsightGrouping.DAY_OF_WEEK ||
    insight.groupBy === InsightGrouping.PERIOD ||
    insight.groupBy === InsightGrouping.WEEKDAY_SAT_SUN
  ) {
    return dateRange;
  }

  let rangeStart, rangeEnd;
  if (dateRange) {
    if (dateRange.dateRangeType === RelativePeriod.CUSTOM) {
      rangeStart = moment(dateRange.startDate).tz(tz, true);
      rangeEnd = moment(dateRange.endDate).tz(tz, true);
    } else {
      const { startDate, endDate } = getDatesForRelativePeriod(
        dateRange.dateRangeType as RelativePeriod,
        max,
        '',
        tz
      );
      rangeStart = moment(startDate);
      rangeEnd = moment(endDate);
    }
  }

  if (
    insight.groupBy === InsightGrouping.HOUR ||
    insight.groupBy === InsightGrouping.DAY
  ) {
    return {
      ...dateRange,
      dateRangeType: RelativePeriod.CUSTOM,
      startDate: activity.day,
      endDate: activity.day,
    };
  }

  if (insight.groupBy === InsightGrouping.WEEK) {
    const startOfWeek = moment(activity.week);
    const endOfWeek = moment(startOfWeek).endOf('week');
    const startDate = rangeStart
      ? moment.max(startOfWeek, rangeStart)
      : startOfWeek;
    const endDate = rangeEnd ? moment.min(endOfWeek, rangeEnd) : endOfWeek;
    return {
      ...dateRange,
      dateRangeType: RelativePeriod.CUSTOM,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
  }

  if (insight.groupBy === InsightGrouping.MONTH) {
    const month = moment()
      .month(activity.month ?? '')
      .format('M');
    const startOfMonth = moment([activity.year ?? 0, Number(month) - 1]);
    const endOfMonth = moment(startOfMonth).endOf('month');
    const startDate = rangeStart
      ? moment.max(startOfMonth, rangeStart)
      : startOfMonth;
    const endDate = rangeEnd ? moment.min(endOfMonth, rangeEnd) : endOfMonth;
    return {
      ...dateRange,
      dateRangeType: RelativePeriod.CUSTOM,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
  }

  if (insight.groupBy === InsightGrouping.YEAR) {
    const startOfYear = moment([activity.year ?? 0]);
    const endOfYear = moment(startOfYear).endOf('year');
    const startDate = rangeStart
      ? moment.max(startOfYear, rangeStart)
      : startOfYear;
    const endDate = rangeEnd ? moment.min(endOfYear, rangeEnd) : endOfYear;
    return {
      ...dateRange,
      dateRangeType: RelativePeriod.CUSTOM,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
  }
};

const getNameForInsightSubscriptionFrequency = (subscription: Subscription) => {
  if (subscription.frequency === SubscriptionFrequency.DAILY) {
    return 'Daily';
  }

  if (subscription.frequency === SubscriptionFrequency.WEEKLY) {
    const dayOfWeek = moment()
      .isoWeekday(subscription.dayOfWeek ?? 1)
      .format('dddd');
    return `Weekly on ${dayOfWeek}`;
  }

  if (subscription.frequency === SubscriptionFrequency.MONTHLY) {
    const modifier = subscription.firstOfMonth ? 'first' : 'last';
    if (subscription.dayOfWeek === undefined) {
      return `Monthly on the ${modifier} day`;
    }

    const dayOfWeek = moment()
      .day(subscription.dayOfWeek - 1)
      .format('dddd');
    return `Monthly on the ${modifier} ${dayOfWeek}`;
  }

  return '';
};

const getInsightLayerGroup = (insight?: Insight) => {
  const layerGroupFilter = insight?.filters?.find(
    (filter) => filter.type === InsightFilterType.LAYER_GROUP
  );

  if (!layerGroupFilter) {
    return null;
  }

  return (layerGroupFilter as InsightEntityFilter).filterIds[0] ?? null;
};

const convertParamsToInsight = (path: string, params: URLSearchParams) => {
  const convertFilters = (dateRange: ObjectMap) => {
    const filters = [];
    if ('routeIds' in dateRange) {
      filters.push({
        type: InsightFilterType.ROUTE,
        comparison: InsightFilterInclusion.IS_IN,
        filterIds: dateRange.routeIds,
      });
    }
    if ('stopIds' in dateRange) {
      filters.push({
        type: InsightFilterType.STOP,
        comparison: InsightFilterInclusion.IS_IN,
        filterIds: dateRange.stopIds,
      });
    }
    if ('tripIds' in dateRange) {
      filters.push({
        type: InsightFilterType.TRIP,
        comparison: InsightFilterInclusion.IS_IN,
        filterIds: dateRange.tripIds,
      });
    }
    return filters;
  };

  const filters = [];
  const dateRanges: RelativeDateRange[] = [];
  let expanded = false;
  if (params.has('firstDateRange')) {
    const firstDateRange = JSON.parse(params.get('firstDateRange') ?? '');
    if (!firstDateRange.dateRangeType) {
      firstDateRange.dateRangeType = getRelativePeriodForDates(
        firstDateRange.startDate,
        firstDateRange.endDate
      );
    }
    dateRanges.push(firstDateRange);
    filters.push(...convertFilters(firstDateRange));
    expanded = firstDateRange.expanded ?? false;
  }
  if (params.has('secondDateRange')) {
    const secondDateRange = JSON.parse(params.get('secondDateRange') ?? '');
    if (!secondDateRange.dateRangeType) {
      secondDateRange.dateRangeType = getRelativePeriodForDates(
        secondDateRange.startDate,
        secondDateRange.endDate
      );
    }
    dateRanges.push(secondDateRange);
  }

  // if somehow we got a url that has no date ranges, reset to default
  if (dateRanges.length === 0) {
    dateRanges.push({
      dateRangeType: RelativePeriod.FOUR_WEEKS,
      days: [1, 1, 1, 1, 1, 1, 1],
    });
  }

  let insightType;
  let displayLayers = [];
  if (/\/(system|routes)$/.test(path)) {
    insightType = InsightType.ROUTES;
    displayLayers.push(DEFAULT_ROUTES_LAYER);
  } else if (/\/routes\/(summary|tags|trips)/.test(path)) {
    insightType = InsightType.STOPS;
    displayLayers.push(DEFAULT_ROUTES_LAYER);
    displayLayers.push(DEFAULT_STOPS_LAYER);
  } else if (/\/stops(\/summary)*/.test(path)) {
    insightType = InsightType.STOPS;
    displayLayers.push(DEFAULT_STOPS_LAYER);
  } else {
    // unrecognized url
    return null;
  }

  return {
    creatorName: 'Team Hopthru',
    creatorId: '',
    insightType,
    dateRanges,
    filters,
    displayLayers,
    name: 'Custom view',
    public: false,
    expanded,
  } as Insight;
};

const getPresetInsight = (preset: 'system' | 'routes' | 'stops') => {
  const dateRange: RelativeDateRange = {
    dateRangeType: RelativePeriod.FOUR_WEEKS,
    days: [1, 1, 1, 1, 1, 1, 1],
  };
  let name;
  let grouping;
  let displayLayers = [];
  if (preset === 'system' || preset === 'routes') {
    grouping = InsightType.ROUTES;
    displayLayers.push(DEFAULT_ROUTES_LAYER);
    name = preset === 'system' ? 'Full system' : 'Routes';
  } else {
    grouping = InsightType.STOPS;
    displayLayers.push(DEFAULT_STOPS_LAYER);
    name = 'Stops';
  }
  return {
    creatorName: 'Team Hopthru',
    creatorId: '',
    insightType: grouping,
    dateRanges: [dateRange],
    displayLayers,
    name,
    public: false,
  } as Insight;
};

const getNameForGrouping = (grouping?: InsightGrouping) => {
  let name = 'None';
  switch (grouping) {
    case InsightGrouping.HOUR:
      name = 'Hour';
      break;
    case InsightGrouping.DAY:
      name = 'Service date';
      break;
    case InsightGrouping.WEEK:
      name = 'Week';
      break;
    case InsightGrouping.MONTH:
      name = 'Month';
      break;
    case InsightGrouping.YEAR:
      name = 'Year';
      break;
    case InsightGrouping.DAY_OF_WEEK:
      name = 'Day of week';
      break;
    case InsightGrouping.PERIOD:
      name = 'Time range';
      break;
    case InsightGrouping.WEEKDAY_SAT_SUN:
      name = 'Weekday, Sat, Sun';
      break;
  }
  return name;
};

const getFilteredEntities = (
  filters: InsightEntityFilter[],
  entities?: string[]
) => {
  const inclusive = filters.filter(
    (filter) => filter.comparison === InsightFilterInclusion.IS_IN
  );
  const exclusive = filters.filter(
    (filter) => filter.comparison === InsightFilterInclusion.IS_NOT_IN
  );
  const includedIds = inclusive.reduce((acc, cur) => {
    cur.filterIds.forEach((filterId) => {
      if (!acc.includes(filterId)) acc.push(filterId);
    });
    return acc;
  }, [] as string[]);
  const excludedIds = exclusive.reduce((acc, cur) => {
    cur.filterIds.forEach((filterId) => {
      if (!acc.includes(filterId)) acc.push(filterId);
    });
    return acc;
  }, [] as string[]);

  if (entities) {
    let filteredIds = entities;
    if (includedIds.length > 0) {
      filteredIds = filteredIds.filter((id) => includedIds.includes(id));
    }
    if (excludedIds.length > 0) {
      filteredIds = filteredIds.filter((id) => !excludedIds.includes(id));
    }
    return filteredIds;
  }

  const filteredIds = includedIds.filter(
    (filterId) => !excludedIds.includes(filterId)
  );
  return filteredIds;
};

// determines if the filters reduce to one entity
// does not account for the case where every entity is filtered out except one
const isFilteredToSingleEntity = (filters: InsightEntityFilter[]) => {
  const filteredIds = getFilteredEntities(filters);
  return filteredIds.length === 1;
};

const getEntityFiltersOfType = (
  filters?: InsightFilter[],
  filterType?: InsightFilterType
) => {
  return (filters ?? []).filter(
    (filter) => filter.type === filterType
  ) as InsightEntityFilter[];
};

const getViewTypeFromInsight = (insight?: Insight) => {
  if (!insight) {
    return ViewType.SYSTEM;
  }

  const routeFilters = getEntityFiltersOfType(
    insight.filters,
    InsightFilterType.ROUTE
  );
  const stopFilters = getEntityFiltersOfType(
    insight.filters,
    InsightFilterType.STOP
  );
  const tripFilters = getEntityFiltersOfType(
    insight.filters,
    InsightFilterType.TRIP
  );
  const layerFilters = getEntityFiltersOfType(
    insight.filters,
    InsightFilterType.LAYER
  );

  if (insight.insightType === InsightType.SYSTEM) {
    return ViewType.SYSTEM;
  }

  if (insight.insightType === InsightType.ROUTES) {
    return ViewType.ROUTES;
  }

  if (
    insight.insightType === InsightType.STOPS ||
    insight.insightType === InsightType.SEGMENTS
  ) {
    if (stopFilters.length === 0 && routeFilters.length === 0) {
      return ViewType.STOPS;
    } else if (
      isFilteredToSingleEntity(routeFilters) &&
      isFilteredToSingleEntity(tripFilters)
    ) {
      return ViewType.TRIP_DETAILS;
    } else if (routeFilters.length > 0) {
      return ViewType.ROUTE_DETAILS;
    } else {
      if (isFilteredToSingleEntity(stopFilters)) {
        return ViewType.STOP_DETAILS;
      } else {
        return ViewType.STOPS;
      }
    }
  }

  if (insight.insightType === InsightType.LAYERS) {
    if (isFilteredToSingleEntity(layerFilters)) {
      return ViewType.LAYER_DETAILS;
    } else {
      return ViewType.LAYERS;
    }
  }

  return ViewType.SYSTEM;
};

const getRouteFromRouteDetailInsight = (insight?: Insight) => {
  const viewType = getViewTypeFromInsight(insight);
  if (viewType !== ViewType.ROUTE_DETAILS) {
    return null;
  }

  const routeFilters = getEntityFiltersOfType(
    insight?.filters,
    InsightFilterType.ROUTE
  );
  return routeFilters[0]?.filterIds[0] ?? null;
};

const getRequiredDisplayLayers = (
  insight?: Insight,
  layerGroups?: LayerGroup[]
) => {
  if (!insight) {
    return [];
  }

  if (
    insight.insightType === InsightType.SYSTEM ||
    insight.insightType === InsightType.ROUTES
  ) {
    return [DEFAULT_ROUTES_LAYER];
  }

  if (insight.insightType === InsightType.STOPS) {
    return [DEFAULT_STOPS_LAYER];
  }

  if (insight.insightType === InsightType.LAYERS) {
    const layerGroupId = getInsightLayerGroup(insight);
    const layerGroup = layerGroups?.find(
      (layerGroup) => layerGroup.id === layerGroupId
    );
    return layerGroup
      ? [
          {
            id: layerGroup.id,
            type: 'layers',
            name: layerGroup.name,
          },
        ]
      : [];
  }

  return [];
};

const ensureDisplayLayers = (insight?: Insight, layerGroups?: LayerGroup[]) => {
  const requiredDisplayLayers = getRequiredDisplayLayers(insight, layerGroups);
  const existingDisplayLayers = insight?.displayLayers ?? [];
  const displayLayers = existingDisplayLayers.concat(
    requiredDisplayLayers.filter(
      (layer) =>
        existingDisplayLayers.findIndex(
          (existingLayer) => existingLayer.id === layer.id
        ) < 0
    )
  );
  return displayLayers;
};

const getRouteDetailView = (routeId: string, insight?: Insight) => {
  if (!insight) {
    return insight;
  }

  const filters = [
    {
      type: InsightFilterType.ROUTE,
      comparison: InsightFilterInclusion.IS_IN,
      filterIds: [routeId],
    },
  ];

  return {
    ...insight,
    groupBy: undefined,
    filters,
    insightType: InsightType.STOPS,
    displayLayers: [DEFAULT_ROUTES_LAYER, DEFAULT_STOPS_LAYER],
  };
};

const getTripDetailView = (
  tripId: string,
  insight?: Insight,
  routeId?: string
) => {
  if (!insight) {
    return insight;
  }

  const filters = [
    {
      type: InsightFilterType.TRIP,
      comparison: InsightFilterInclusion.IS_IN,
      filterIds: [tripId],
    },
  ];

  if (routeId) {
    filters.push({
      type: InsightFilterType.ROUTE,
      comparison: InsightFilterInclusion.IS_IN,
      filterIds: [routeId],
    });
  }

  return {
    ...insight,
    groupBy: undefined,
    filters,
    insightType: InsightType.STOPS,
    displayLayers: [DEFAULT_ROUTES_LAYER, DEFAULT_STOPS_LAYER],
  };
};

const getStopDetailView = (stopId: string, insight?: Insight) => {
  if (!insight) {
    return insight;
  }

  const filters = [
    {
      type: InsightFilterType.STOP,
      comparison: InsightFilterInclusion.IS_IN,
      filterIds: [stopId],
    },
  ];

  return {
    ...insight,
    groupBy: undefined,
    filters,
    insightType: InsightType.STOPS,
    displayLayers: [DEFAULT_STOPS_LAYER],
  };
};

const getLayerDetailView = (layerId: string, insight?: Insight) => {
  const layerGroup = getInsightLayerGroup(insight);

  if (!insight || !layerGroup) {
    return insight;
  }

  const filters = (insight.filters ?? []).filter(
    (filter) => filter.type === InsightFilterType.LAYER_GROUP
  );
  filters.push({
    type: InsightFilterType.LAYER,
    comparison: InsightFilterInclusion.IS_IN,
    filterIds: [layerId],
  });

  return {
    ...insight,
    groupBy: undefined,
    filters,
    insightType: InsightType.LAYERS,
    displayLayers: [
      {
        id: layerGroup,
        type: 'layers',
        name: layerGroup,
      },
    ],
  };
};

const isSupportedMapView = (insight?: Insight) => {
  if (!insight) {
    return false;
  }

  if (
    !['routes', 'stops', 'segments', 'layers'].includes(insight.insightType) ||
    !!insight.groupBy
  ) {
    return false;
  }

  return true;
};

const stripDisplayProperties = (insight?: Insight) => {
  if (!insight) {
    return insight;
  }

  const strippedInsight = { ...insight, name: 'Stripped Insight' };
  delete strippedInsight.displayLayers;
  delete strippedInsight.displayColumns;
  delete strippedInsight.displayLegend;

  if (strippedInsight?.filters?.length === 0) {
    delete strippedInsight.filters;
  }

  delete strippedInsight.public;
  delete strippedInsight.isSubscribed;
  return strippedInsight;
};

const getNameField = (insight?: Insight) => {
  switch (insight?.insightType) {
    case InsightType.STOPS:
      return 'gtfsStopName';
    case InsightType.LAYERS:
      return 'layerName';
    case InsightType.ROUTES:
      return 'gtfsRouteShortName';
    default:
      return undefined;
  }
};

const getEntityFiltersFromResults = (
  insight?: Insight,
  results: InsightActivity[] = []
) => {
  if (!insight) {
    return;
  }

  let filterType;
  switch (insight.insightType) {
    case InsightType.ROUTES:
      filterType = InsightFilterType.ROUTE;
      break;
    case InsightType.STOPS:
      filterType = InsightFilterType.STOP;
      break;
    case InsightType.LAYERS:
      filterType = InsightFilterType.LAYER;
      break;
    case InsightType.TRIPS:
      filterType = InsightFilterType.TRIP;
      break;
  }

  if (!filterType) {
    return;
  }

  const ids = results.map((result) => result.id).filter(Boolean);
  return [
    {
      type: filterType,
      comparison: InsightFilterInclusion.IS_IN,
      filterIds: ids,
    },
  ];
};

const isValidInsightFilter = (
  insightType?: InsightType,
  filterType?: InsightFilterType
) => {
  switch (filterType) {
    case InsightFilterType.ROUTE:
      return (
        insightType === InsightType.ROUTES ||
        insightType === InsightType.STOPS ||
        insightType === InsightType.SEGMENTS ||
        insightType === InsightType.TRIPS
      );
    case InsightFilterType.STOP:
      return (
        insightType === InsightType.ROUTES ||
        insightType === InsightType.STOPS ||
        insightType === InsightType.SEGMENTS
      );
    case InsightFilterType.TRIP:
      return (
        insightType === InsightType.STOPS ||
        insightType === InsightType.TRIPS ||
        insightType === InsightType.SEGMENTS
      );
    case InsightFilterType.LAYER:
      return true;
    case InsightFilterType.KPI:
      return true;
    case InsightFilterType.START_TIME:
    case InsightFilterType.END_TIME:
      return insightType === InsightType.TRIPS;
    case InsightFilterType.DIRECTION:
    case InsightFilterType.VARIANT:
      return (
        insightType === InsightType.ROUTES ||
        insightType === InsightType.STOPS ||
        insightType === InsightType.TRIPS ||
        insightType === InsightType.SEGMENTS
      );
    case InsightFilterType.TAG:
      return true;
    default:
      return false;
  }
};

const validateSingleLegend = (
  legend: number[] | undefined,
  colors: string[]
) => {
  const errorArray = new Array(colors.length - 1).fill(0);

  if (!legend) {
    return { isError: false, error: errorArray };
  }

  if (legend.length < colors.length - 1) {
    const diff = colors.length - 1 - legend.length;
    errorArray.splice(legend.length, diff, ...new Array(diff).fill(1));
    return { isError: true, error: errorArray };
  }

  let isAscending = true;
  for (let i = 1; i < legend.length; i++) {
    if (legend[i] < legend[i - 1]) {
      errorArray[i] = 1;
      isAscending = false;
    }
  }

  if (!isAscending) {
    return { isError: true, error: errorArray };
  }

  return { isError: false, error: errorArray };
};

const validateLegend = (legend: Legend, colors: string[]) => {
  let isValid = true;
  for (let [, v] of Object.entries(legend)) {
    const { isError } = validateSingleLegend(v, colors);
    isValid = isValid && !isError;
  }
  return isValid;
};

const trackInsightEvent = (eventName: string, insight?: Partial<Insight>) => {
  const filterTypes = insight?.filters?.map(({ type }) => type);
  const timeGrouping = insight?.groupBy;
  const viewType = store.getState().layout.present.insights.viewType;
  tracking.event(eventName, {
    dataType: insight?.insightType,
    filterTypes,
    timeGrouping,
    viewType,
  });
};

export {
  convertParamsToInsight,
  ensureDisplayLayers,
  filterValidInsightColumns,
  getEntityFiltersFromResults,
  getFilteredEntities,
  getInsightGroupingColumns,
  getInsightLayerGroup,
  getRequiredDisplayLayers,
  getEntityFiltersOfType,
  getLayerDetailView,
  getNameForGrouping,
  getNameForInsightType,
  getNameForInsightResultKey,
  getPresetInsight,
  getRequiredInsightColumns,
  getResultDateRange,
  getRouteFromRouteDetailInsight,
  getRouteDetailView,
  getStopDetailView,
  getTripDetailView,
  getNameField,
  getNameForInsightSubscriptionFrequency,
  getValidInsightActivityColumns,
  getValidInsightEntityColumns,
  getViewTypeFromInsight,
  isFilteredToSingleEntity,
  isValidInsightFilter,
  isSupportedMapView,
  validateSingleLegend,
  validateLegend,
  partitionByCreator,
  partitionByType,
  stripDisplayProperties,
  trackInsightEvent,
};
