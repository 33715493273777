import React from 'react';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import { Insight, InsightType } from '../../types';
import { getNameForInsightType } from '../../../../utils/insights';

type InsightListTabPanelProps = {
  data: { [key in InsightType]?: Insight[] };
  value: number;
  index: number;
  onInsightClick: (insight: Insight) => void;
};

export default function InsightListTabPanel({
  data,
  value,
  index,
  onInsightClick,
}: InsightListTabPanelProps) {
  const isEmpty = Object.keys(data).length === 0;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`insight-list-tabpanel-${index}`}
      aria-labelledby={`insight-list-tab-${index}`}
    >
      {isEmpty && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          height={80}
        >
          No results found
        </Box>
      )}
      {!isEmpty && (
        <List
          dense
          subheader={<li />}
          sx={{
            bgcolor: 'primary.main',
            maxHeight: 400,
            overflow: 'auto',
          }}
        >
          {Object.entries(data).map(([insightType, insights], index) => {
            return (
              <Box bgcolor='inherit' component='li' key={insightType}>
                <Box bgcolor='inherit' component='ul' padding={0}>
                  <ListSubheader>
                    {getNameForInsightType(insightType as InsightType)}
                  </ListSubheader>
                  {insights.map((insight) => (
                    <ListItemButton
                      key={insight.id}
                      onClick={() => onInsightClick(insight)}
                    >
                      {insight.name}
                    </ListItemButton>
                  ))}
                  {index !== Object.keys(data).length - 1 && (
                    <Box py={1} px={2}>
                      <Divider />
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </List>
      )}
    </Box>
  );
}
