import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Star, StarBorder } from '@mui/icons-material';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import { selectAgency } from '../../store/slices/userSlice';
import {
  selectActiveInsight,
  setActiveInsight,
} from '../../store/slices/layoutSlice';
import {
  useAddInsightFavoriteMutation,
  useDeleteInsightFavoriteMutation,
} from '../../store/slices/apiSlice';
import { trackInsightEvent } from '../../utils/insights';

export default function FavoriteButton() {
  const dispatch = useDispatch();
  const { agencyId } = useSelector(selectAgency);
  const activeView = useSelector(selectActiveInsight);
  const isFavorited = !!activeView?.isFavorited;
  const [addFavorite] = useAddInsightFavoriteMutation();
  const [deleteFavorite] = useDeleteInsightFavoriteMutation();

  const toggleFavorite = (isFavorited: boolean) => {
    // perform appropriate favorite mutation, and optimistically update the active insight
    if (activeView) {
      if (isFavorited) {
        deleteFavorite({ agencyId, insightId: activeView.id });
        dispatch(setActiveInsight({ ...activeView, isFavorited: false }));
        trackInsightEvent('analyze-insight-unfavorited');
      } else {
        addFavorite({ agencyId, insightId: activeView.id });
        dispatch(setActiveInsight({ ...activeView, isFavorited: true }));
        trackInsightEvent('analyze-insight-favorited');
      }
    }
  };

  return (
    <HeaderMenuButtonV2
      aria-label='Toggle favorite'
      disabled={!activeView}
      title={isFavorited ? 'Unfavorite' : 'Favorite'}
      onClick={() => toggleFavorite(isFavorited)}
      variant='dark'
    >
      {isFavorited ? <Star /> : <StarBorder />}
    </HeaderMenuButtonV2>
  );
}
