import moment from 'moment-timezone';
import { lossyDeepClone } from './utils';

export enum PreviousRelativePeriod {
  PERIOD = 'period',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum RelativePeriod {
  SEVEN_DAYS = 'last_7_days',
  FOUR_WEEKS = 'last_4_weeks',
  THREE_MONTHS = 'last_3_months',
  TWELVE_MONTHS = 'last_12_months',
  MONTH_TO_DATE = 'month_to_date',
  QUARTER_TO_DATE = 'quarter_to_date',
  YEAR_TO_DATE = 'year_to_date',
  CUSTOM = 'custom',
}

const ERROR_MESSAGES = {
  MAX_RANGE_EXCEEDED: 'The maximum date range is 365 days',
  OUT_OF_BOUNDS_START: (date: string) => `Data is not available before ${date}`,
  OUT_OF_BOUNDS_END: (date: string) => `Data is not available after ${date}`,
  INVALID_FORMAT: 'The date range is invalid',
};

const getDatesForPreviousPeriod = (
  startDate: string,
  endDate: string,
  period: PreviousRelativePeriod,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  let mSDate = moment(startDate).tz(tz, true);
  let mEDate = moment(endDate).tz(tz, true);
  switch (period) {
    case PreviousRelativePeriod.YEAR:
      mSDate.subtract(1, 'years');
      mEDate.subtract(1, 'years');
      break;
    case PreviousRelativePeriod.QUARTER:
      mSDate.subtract(3, 'months');
      mEDate.subtract(3, 'months');
      break;
    case PreviousRelativePeriod.MONTH:
      mSDate.subtract(1, 'months');
      mEDate.subtract(1, 'months');
      break;
    case PreviousRelativePeriod.PERIOD:
    default: {
      const period = moment.duration(mEDate.diff(mSDate)).asDays();
      mSDate.subtract(1, 'days');
      mEDate = mSDate.clone().subtract(period, 'days');
      [mSDate, mEDate] = [mEDate, mSDate];
      break;
    }
  }
  return {
    startDate: mSDate.format('YYYY-MM-DD'),
    endDate: mEDate.format('YYYY-MM-DD'),
  };
};

const getDatesForRelativePeriod = (
  period: RelativePeriod,
  max?: string,
  format?: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  const maxDate = moment(max).tz(tz, true);
  let startDate;
  switch (period) {
    case RelativePeriod.SEVEN_DAYS:
      startDate = maxDate.clone().subtract(6, 'days');
      break;
    case RelativePeriod.FOUR_WEEKS:
      startDate = maxDate.clone().subtract(4, 'weeks').add(1, 'days');
      break;
    case RelativePeriod.THREE_MONTHS:
      startDate = maxDate.clone().subtract(3, 'months').add(1, 'days');
      break;
    case RelativePeriod.TWELVE_MONTHS:
      startDate = maxDate.clone().subtract(12, 'months').add(1, 'days');
      break;
    case RelativePeriod.MONTH_TO_DATE:
      startDate = maxDate.clone().startOf('month');
      break;
    case RelativePeriod.QUARTER_TO_DATE:
      startDate = maxDate.clone().startOf('quarter');
      break;
    case RelativePeriod.YEAR_TO_DATE:
      startDate = maxDate.clone().startOf('year');
      break;
    case RelativePeriod.CUSTOM:
    default:
      startDate = maxDate.clone().subtract(1, 'days');
      break;
  }

  return {
    startDate: startDate.format(format ?? 'YYYY-MM-DD'),
    endDate: maxDate.format(format ?? 'YYYY-MM-DD'),
  };
};

const getRelativePeriodForDates = (
  startDate: string,
  endDate: string,
  max?: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  const maxDate = moment(max).tz(tz, true);
  const mSDate = moment(startDate).tz(tz, true);
  const mEDate = moment(endDate).tz(tz, true);
  if (maxDate.isSame(mEDate, 'day')) {
    if (maxDate.clone().subtract(6, 'days').isSame(mSDate, 'day')) {
      return RelativePeriod.SEVEN_DAYS;
    }
    if (
      maxDate.clone().subtract(4, 'weeks').add(1, 'days').isSame(mSDate, 'day')
    ) {
      return RelativePeriod.FOUR_WEEKS;
    }
    if (
      maxDate.clone().subtract(3, 'months').add(1, 'days').isSame(mSDate, 'day')
    ) {
      return RelativePeriod.THREE_MONTHS;
    }
    if (
      maxDate
        .clone()
        .subtract(12, 'months')
        .add(1, 'days')
        .isSame(mSDate, 'day')
    ) {
      return RelativePeriod.TWELVE_MONTHS;
    }
    if (maxDate.clone().startOf('month').isSame(mSDate, 'day')) {
      return RelativePeriod.MONTH_TO_DATE;
    }
    if (maxDate.clone().startOf('quarter').isSame(mSDate, 'day')) {
      return RelativePeriod.QUARTER_TO_DATE;
    }
    if (maxDate.clone().startOf('year').isSame(mSDate, 'day')) {
      return RelativePeriod.YEAR_TO_DATE;
    }
  }
  return RelativePeriod.CUSTOM;
};

const isRelativePeriodValid = (
  period: RelativePeriod,
  min: string,
  max?: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  const minDate = moment(min).tz(tz, true);
  const maxDate = moment(max).tz(tz, true);
  switch (period) {
    case RelativePeriod.SEVEN_DAYS:
      return minDate.isSameOrBefore(maxDate.clone().subtract(6, 'days'));
    case RelativePeriod.FOUR_WEEKS:
      return minDate.isSameOrBefore(
        maxDate.clone().subtract(4, 'weeks').add(1, 'days')
      );
    case RelativePeriod.THREE_MONTHS:
      return minDate.isSameOrBefore(
        maxDate.clone().subtract(3, 'months').add(1, 'days')
      );
    case RelativePeriod.TWELVE_MONTHS:
      return minDate.isSameOrBefore(
        maxDate.clone().subtract(12, 'months').add(1, 'days')
      );
    case RelativePeriod.MONTH_TO_DATE:
      return minDate.isSameOrBefore(maxDate.clone().startOf('month'));
    case RelativePeriod.QUARTER_TO_DATE:
      return minDate.isSameOrBefore(maxDate.clone().startOf('quarter'));
    case RelativePeriod.YEAR_TO_DATE:
      return minDate.isSameOrBefore(maxDate.clone().startOf('year'));
    default:
      return true;
  }
};

const isPreviousPeriodValid = (
  period: PreviousRelativePeriod,
  startDate: string,
  endDate: string,
  min: string,
  timezone?: string
) => {
  const tz = timezone || moment.tz.guess();
  const minDate = moment(min).tz(tz, true);
  const mSDate = moment(startDate).tz(tz, true);
  const mEDate = moment(endDate).tz(tz, true);
  switch (period) {
    case PreviousRelativePeriod.YEAR:
      return minDate.isSameOrBefore(mSDate.clone().subtract(1, 'years'));
    case PreviousRelativePeriod.QUARTER:
      return minDate.isSameOrBefore(mSDate.clone().subtract(1, 'quarters'));
    case PreviousRelativePeriod.MONTH:
      return minDate.isSameOrBefore(mSDate.clone().subtract(1, 'months'));
    case PreviousRelativePeriod.PERIOD: {
      const period = moment.duration(mEDate.diff(mSDate)).asDays();
      return minDate.isSameOrBefore(
        mSDate.clone().subtract(1 + period, 'days')
      );
    }
    default:
      return true;
  }
};

const isRelativeDateRange = (
  dateRange: DateRange | RelativeDateRange
): dateRange is RelativeDateRange => {
  return (dateRange as RelativeDateRange).dateRangeType !== undefined;
};

const getErrorForDateRange = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  timezone,
  disableMaxRange,
}: {
  startDate?: string;
  endDate?: string;
  minDate?: string;
  maxDate?: string;
  timezone: string;
  disableMaxRange?: boolean;
}) => {
  const mSDate = moment(startDate).tz(timezone, true);
  const mEDate = moment(endDate).tz(timezone, true);
  const mMinDate = moment(minDate).tz(timezone, true);
  const mMaxDate = moment(maxDate).tz(timezone, true);

  let isInvalid = false;
  let errorMessage = '';

  if (endDate && startDate && !disableMaxRange) {
    isInvalid = isInvalid || mEDate.diff(mSDate, 'days') > 365;
    errorMessage = isInvalid ? ERROR_MESSAGES.MAX_RANGE_EXCEEDED : errorMessage;
  }

  if (!mSDate.isValid() || !mEDate.isValid()) {
    isInvalid = true;
    errorMessage = ERROR_MESSAGES.INVALID_FORMAT;
  }

  if (minDate && mSDate.isBefore(mMinDate, 'day')) {
    isInvalid = true;
    errorMessage = ERROR_MESSAGES.OUT_OF_BOUNDS_START(
      mMinDate.format('MM/DD/YYYY')
    );
  }

  if (maxDate && mEDate.isAfter(mMaxDate, 'day')) {
    isInvalid = true;
    errorMessage = ERROR_MESSAGES.OUT_OF_BOUNDS_END(
      mMaxDate.format('MM/DD/YYYY')
    );
  }

  if (mEDate.isBefore(mSDate, 'day')) {
    isInvalid = true;
    errorMessage = ERROR_MESSAGES.INVALID_FORMAT;
  }

  return errorMessage;
};

const getWeekdayWeekendState = (daysOfWeek?: number[]) => {
  if (!daysOfWeek) {
    return { weekdays: 0, weekends: 0 };
  }

  const weekdays = daysOfWeek.slice(0, 5);
  const weekends = daysOfWeek.slice(5);

  function getState(days: number[]) {
    let state = 2;
    if (days.every((day) => day === 1)) {
      state = 1;
    } else if (days.every((day) => day === 0)) {
      state = 0;
    }
    return state;
  }

  return {
    weekdays: getState(weekdays),
    weekends: getState(weekends),
  };
};

const cleanDateRange = <T extends DateRange | RelativeDateRange>(
  dateRange: T
) => {
  const cleaned = lossyDeepClone(dateRange);
  if ('weekdays' in cleaned) {
    delete cleaned.weekdays;
  }
  if ('weekends' in cleaned) {
    delete cleaned.weekends;
  }
  for (const key of Object.keys(cleaned)) {
    const value = cleaned[key as keyof T];
    if (typeof value === 'string' && value.length === 0) {
      delete cleaned[key as keyof T];
    }
  }
  return cleaned;
};

const getMaxDate = (
  agencyDataStartDate: string,
  agencyDataFeedDaysTrailing: number,
  agencyTimeZone: string = 'Etc/UTC',
  agencyDataEndDate?: string
) => {
  const minDate = moment(agencyDataStartDate).tz(agencyTimeZone);
  const maxDate = moment.max(
    minDate,
    moment().tz(agencyTimeZone).subtract(agencyDataFeedDaysTrailing, 'd')
  );

  // if no agency end date is provided, this will default to the current day,
  // which is the maximum it could possibly be
  const endDate = moment(agencyDataEndDate || undefined).tz(agencyTimeZone);
  return moment.min(maxDate, endDate);
};

const getLabelForRelativePeriod = (period: RelativePeriod) => {
  const labels = new Map([
    [RelativePeriod.SEVEN_DAYS, 'Last 7 days'],
    [RelativePeriod.FOUR_WEEKS, 'Last 4 weeks'],
    [RelativePeriod.THREE_MONTHS, 'Last 3 months'],
    [RelativePeriod.TWELVE_MONTHS, 'Last 12 months'],
    [RelativePeriod.MONTH_TO_DATE, 'Month to date'],
    [RelativePeriod.QUARTER_TO_DATE, 'Quarter to date'],
    [RelativePeriod.YEAR_TO_DATE, 'Year to date'],
    [RelativePeriod.CUSTOM, 'Custom'],
  ]);

  return labels.get(period);
};

const getAgencyDateRange = (
  agencyDataStartDate: string,
  agencyDataFeedDaysTrailing: number,
  agencyTimeZone: string = 'Etc/UTC',
  agencyDataEndDate?: string
): RelativeDateRange => {
  const minDate = moment(agencyDataStartDate).tz(agencyTimeZone);
  const maxDate = getMaxDate(
    agencyDataStartDate,
    agencyDataFeedDaysTrailing,
    agencyTimeZone,
    agencyDataEndDate
  );
  return {
    dateRangeType: RelativePeriod.CUSTOM,
    startDate: minDate.format('YYYY-MM-DD'),
    endDate: maxDate.format('YYYY-MM-DD'),
    days: [1, 1, 1, 1, 1, 1, 1],
  };
};

const getTotalDaysForDateRange = (dateRange: RelativeDateRange) => {
  switch (dateRange.dateRangeType) {
    case RelativePeriod.FOUR_WEEKS:
      return 28;
    case RelativePeriod.SEVEN_DAYS:
      return 7;
    case RelativePeriod.THREE_MONTHS:
      return 90;
    case RelativePeriod.TWELVE_MONTHS:
      return 365;
    case RelativePeriod.MONTH_TO_DATE:
      return moment().date();
    case RelativePeriod.YEAR_TO_DATE:
      return moment().dayOfYear();
    case RelativePeriod.QUARTER_TO_DATE:
      return moment().diff(moment().startOf('quarter'), 'days');
    case RelativePeriod.CUSTOM:
    default:
      return moment(dateRange.endDate).diff(
        moment(dateRange.startDate),
        'days'
      );
  }
};

export {
  ERROR_MESSAGES,
  cleanDateRange,
  getAgencyDateRange,
  getMaxDate,
  getTotalDaysForDateRange,
  isPreviousPeriodValid,
  isRelativePeriodValid,
  isRelativeDateRange,
  getDatesForPreviousPeriod,
  getDatesForRelativePeriod,
  getErrorForDateRange,
  getLabelForRelativePeriod,
  getRelativePeriodForDates,
  getWeekdayWeekendState,
};
