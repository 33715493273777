import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LogoSecondary from '../../assets/hopthru_logo_secondary.svg?react';

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ theme, size }) => ({
  height: size,
  width: size,
  flexShrink: 0,
}));

export default function HopthruLogo({ size = 44 }) {
  return (
    <StyledLink
      to='/'
      title='Hopthru by Swiftly'
      aria-label='Hopthru by Swiftly'
      size={size}
    >
      <LogoSecondary viewBox='0 0 190 190' height='100%' width='100%' />
    </StyledLink>
  );
}
