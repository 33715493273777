import tinycolor from 'tinycolor2';

const primary = '#ffffff';
const secondary = '#202124';
const tertiary = '#dfe1e5';
const warning = '#f23b2f';
const success = '#3CD4A0';
const info = '#202124';
const alternate = '#a0b6e4';

const lightenRate = 7.5;
const darkenRate = 15;

const theme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
    tertiary: {
      main: tertiary,
      light: tinycolor(tertiary).lighten(lightenRate).toHexString(),
      dark: tinycolor(tertiary).darken(darkenRate).toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    alternate: {
      main: alternate,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#0c0c0c',
      secondary: '#696969',
      hint: '#B9B9B9',
    },
    background: {
      default: '#FFFFFF',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
        groupedHorizontal: {
          '&.MuiToggleButtonGroup-firstButton': {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          },
          '&.MuiToggleButtonGroup-lastButton': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
        groupedVertical: {
          '&.MuiToggleButtonGroup-firstButton': {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          '&.MuiToggleButtonGroup-lastButton': {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '8px 0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.8125rem',
          margin: 0,
          paddingBottom: 8,
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
          '& .MuiCheckbox-root': {
            padding: 0,
            paddingRight: 16,
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            fontWeight: 500,
          },
          '&.MuiButtonBase-root': {
            display: 'flex',
            paddingBottom: 8,
            paddingTop: 8,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#000000',
          height: 20,
          width: 20,
          top: 'inherit',
        },
        outlined: {
          '&:valid, &:hover, &:focus, &:active': {
            borderRadius: 10,
          },
        },
        root: {
          height: 'auto',
        },
        select: {
          minHeight: 'auto',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          textTransform: 'none',
          boxShadow: 'none',
          letterSpacing: 'normal',
          padding: '6px 16px',
          height: '32px',
          fontWeight: 400,
          fontSize: '0.8125rem',
          lineHeight: 1.23,
          '&:focus': {
            backgroundColor: 'rgba(74, 74, 74, 0.05)',
          },
          '&.Mui-focusVisible': {
            outline: '2px solid #202124',
            outlineOffset: 2,
          },
          variants: [],
        },
        contained: {
          boxShadow: 'none',
          fontWeight: 500,
          '&:hover, &:active, &.Mui-focusVisible': {
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          '&:focus, &:hover': {
            backgroundColor: '#323438',
          },
        },
        outlined: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
          color: secondary,
          padding: '5px 15px',
          '&:focus, &:hover': {
            borderColor: secondary,
            backgroundColor: 'rgba(12, 12, 12, 0.04)',
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.38)',
            borderColor: tertiary,
          },
        },
        sizeSmall: {
          padding: '5px 12px',
          '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outline: '2px solid #202124',
            outlineOffset: 2,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: '#202124',
          padding: '8px 16px',
          lineHeight: 1,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 500,
          },
          '&:selected, &:focus': {
            backgroundColor: '#eff1f2',
          },
        },
        button: {
          '&:hover, &:focus': {
            backgroundColor: '#eff1f2',
          },
        },
        gutters: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#202124',
          minWidth: 36,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: 36,
        },
        primary: {
          lineHeight: 1,
          fontSize: '0.875rem',
        },
        secondary: {
          lineHeight: 1,
          fontSize: '0.875rem',
        },
        root: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          borderBottom: '1px solid #dadce0',
          lineHeight: 'normal',
          padding: 16,
          whiteSpace: 'nowrap',
        },
        head: {
          fontWeight: 500,
          color: '#202124',
          backgroundColor: '#eff1f2',
          lineHeight: 'normal',
          padding: '12px 16px',
          '&:first-child': {
            borderTopLeftRadius: '10px',
          },
          '&:last-child': {
            borderTopRightRadius: '10px',
          },
        },
        stickyHeader: {
          backgroundColor: '#eff1f2',
        },
        paddingCheckbox: {
          padding: 0,
          paddingLeft: 4,
          width: 48,
        },
        paddingNone: {
          padding: 0,
        },
        sizeSmall: {
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          letterSpacing: 'normal',
          textTransform: 'none',
          minWidth: 80,
          whiteSpace: 'nowrap',
          '&.Mui-selected': {
            color: secondary,
          },
          variants: [],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
          borderBottom: '1px solid #DADCE0',
          variants: [],
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: secondary,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 10,
        },
        marginDense: {
          height: 32,
        },
        root: {
          fontSize: '0.8125rem',
          position: 'relative',
          lineHeight: '1rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '& .MuiOutlinedInput-input': {
            height: 'auto',
          },
          '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: secondary,
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
            },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: secondary,
            borderWidth: 1,
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: tertiary,
            },
          },
          variants: [],
        },
        input: {
          padding: 12,
        },
        inputSizeSmall: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingRight: 12,
          paddingLeft: 12,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#202124',
          '&.Mui-focused': {
            color: '#202124',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#202124',
          lineHeight: 1,
          '&.Mui-focused': {
            color: '#202124',
          },
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            fontSize: '1rem',
            transform: 'translate(0px, -24px)',
          },
        },
        sizeSmall: {
          color: '#696969',
          '&.Mui-focused': {
            color: '#696969',
          },
          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '0.8125rem',
            transform: 'translate(16px, -21px)',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          minWidth: 400,
          padding: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 20,
        },
        labelSmall: {
          fontSize: '0.6875rem',
          fontWeight: 500,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: secondary,
          borderRadius: 10,
          boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.25)',
          fontSize: '0.8125rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            flexWrap: 'nowrap',
            padding: 12,
            '& .MuiAutocomplete-input, & .MuiAutocompleteinput:first-child': {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        option: {
          fontSize: '0.8125rem',
          paddingBottom: 8,
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
          '& .MuiCheckbox-root': {
            padding: 0,
            paddingRight: 16,
          },
        },
        loading: {
          fontSize: '0.8125rem',
        },
        noOptions: {
          fontSize: '0.8125rem',
        },
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiPickersToolbarButton: {
      styleOverrides: {
        toolbarBtn: {
          height: 'auto',
        },
      },
    },
    MuiPickersToolbarText: {
      styleOverrides: {
        toolbarTxt: {
          fontSize: '3.75rem',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#696969',
          '&.Mui-checked': {
            color: secondary,
          },
          '&.MuiCheckbox-indeterminate': {
            color: '#696969',
          },
        },
        colorSecondary: {
          '&.Mui-disabled': {
            color: tertiary,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          backgroundColor: secondary,
          color: primary,
          '&:hover': {
            backgroundColor: secondary,
            opacity: 0.8,
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            backgroundColor: secondary,
          },
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        item: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: secondary,
            '& + .MuiSwitch-track': {
              backgroundColor: secondary,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: secondary,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputAdornedStart: {
          paddingLeft: '0 !important',
        },
      },
    },
  },
};

export default theme;
