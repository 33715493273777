import React, { Suspense, lazy, useEffect } from 'react';
import { refreshToken } from '../../store/effects/user';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
  useFeatureEnabled,
  KNOWN_FEATURES,
} from '../../context/FeatureProvider';
import HeaderV2 from '../Header/HeaderV2';

// components
import LoadingSpinner from '../LoadingSpinner';

// pages
import { selectAgency } from '../../store/slices/userSlice';

const Settings = lazy(
  () =>
    import(/* webpackChunkName: "Settings" */ '../../pages/settings/Settings')
);
const Analyze = lazy(
  () => import(/* webpackChunkName: "Analyze" */ '../../pages/views/AnalyzeV2')
);

function LayoutV2(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const { showAtypicalDays } = useSelector(selectAgency);

  const showDiagnosticReporting = useFeatureEnabled(
    KNOWN_FEATURES.SHOW_DIAGNOSTIC_REPORTING
  );

  useEffect(() => {
    // Refresh auth token every 60 minutes
    const interval = setInterval(() => {
      dispatch(refreshToken());
    }, 3600000);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Box
      display='flex'
      maxWidth='100vw'
      height='100vh'
      overflow='hidden auto'
      flexDirection='column'
    >
      <HeaderV2 />
      <Suspense
        fallback={
          <Box flexGrow={1}>
            <LoadingSpinner message='Loading...' showError={false} />
          </Box>
        }
      >
        <Box
          width='100vw'
          display='flex'
          flexGrow={1}
          flexDirection='column'
          minHeight={0}
        >
          <Switch>
            <Route exact path={['/', '/system']}>
              <Analyze />
            </Route>
            <Route
              path={[
                '/routes',
                '/trips',
                '/stops',
                '/insights/:id',
                '/views/:id',
              ]}
            >
              <Analyze />
            </Route>
            <Route path='/settings/profile'>
              <Settings rightPanel='profile' />
            </Route>
            <Route path='/settings/team'>
              <Settings rightPanel='team' />
            </Route>
            <Route
              path='/settings/routes/:id'
              render={({ match }) => (
                <Settings routeId={match.params.id} rightPanel='routeDetails' />
              )}
            />
            <Route path='/settings/routes'>
              <Settings rightPanel='routes' />
            </Route>
            <Route
              path='/settings/stops/:id'
              render={({ match }) => (
                <Settings stopId={match.params.id} rightPanel='stopDetails' />
              )}
            />
            <Route path='/settings/stops'>
              <Settings rightPanel='stops' />
            </Route>
            <Route path='/settings/tags/:id/add'>
              <Settings rightPanel='addTags' />
            </Route>
            <Route path='/settings/tags/:id'>
              <Settings rightPanel='tagDetails' />
            </Route>
            <Route path='/settings/tags'>
              <Settings rightPanel='tagTypes' />
            </Route>
            {showDiagnosticReporting && [
              <Route exact path='/settings/vehicles' key='vehicle-overview'>
                <Settings rightPanel='vehicles' />
              </Route>,
              <Route path='/settings/vehicles/:id' key='vehicle-details'>
                <Settings rightPanel='vehicleDetails' />
              </Route>,
              <Route exact path='/settings/vehicle_issues' key='vehicle-issues'>
                <Settings rightPanel='vehicleIssues' />
              </Route>,
            ]}
            {showAtypicalDays && (
              <Route path='/settings/holidays'>
                <Settings rightPanel='holidays' />
              </Route>
            )}
            <Route path='/settings/data/linked'>
              <Settings rightPanel='linkedData' />
            </Route>
            <Route path='/settings/data/unlinked'>
              <Settings rightPanel='unlinkedData' />
            </Route>
            <Route path='/settings/export'>
              <Settings rightPanel='export' />
            </Route>
            <Route exact path='/settings'>
              <Redirect to='/settings/profile' />
            </Route>
          </Switch>
        </Box>
      </Suspense>
    </Box>
  );
}

export default withRouter(LayoutV2);
