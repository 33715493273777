import queryString from 'query-string';
import { Moment } from 'moment-timezone';
import { getDatesForRelativePeriod, RelativePeriod } from './dates';

const getSystemLink = ({
  agencyId,
  expanded,
  dateRange,
  maxDate,
}: {
  agencyId?: string;
  expanded?: boolean;
  dateRange: RelativeDateRange;
  maxDate: Moment | string;
}) => {
  const {
    dateRangeType,
    startDate: fixedStartDate,
    endDate: fixedEndDate,
    days,
    timeRange,
  } = dateRange;
  let startDate = fixedStartDate;
  let endDate = fixedEndDate;
  if (dateRangeType !== RelativePeriod.CUSTOM) {
    const max = typeof maxDate === 'string' ? maxDate : maxDate.format();
    const { startDate: relativeStartDate, endDate: relativeEndDate } =
      getDatesForRelativePeriod(dateRangeType as RelativePeriod, max);
    startDate = relativeStartDate;
    endDate = relativeEndDate;
  }

  const params = queryString.stringify(
    {
      agencyId,
      firstDateRange: JSON.stringify({
        days,
        endDate,
        expanded,
        startDate,
        timeRange: timeRange ?? '',
      }),
    },
    { arrayFormat: 'comma' }
  );
  return `/system?${params}`;
};

const getStopDetailsLink = ({
  agencyId,
  expanded,
  dateRange,
  maxDate,
  stopId,
}: {
  agencyId?: string;
  expanded?: boolean;
  dateRange: RelativeDateRange;
  maxDate: Moment | string;
  stopId: string;
}) => {
  const {
    dateRangeType,
    startDate: fixedStartDate,
    endDate: fixedEndDate,
    days,
    timeRange,
  } = dateRange;
  let startDate = fixedStartDate;
  let endDate = fixedEndDate;
  if (dateRangeType !== RelativePeriod.CUSTOM) {
    const max = typeof maxDate === 'string' ? maxDate : maxDate.format();
    const { startDate: relativeStartDate, endDate: relativeEndDate } =
      getDatesForRelativePeriod(dateRangeType as RelativePeriod, max);
    startDate = relativeStartDate;
    endDate = relativeEndDate;
  }

  const params = queryString.stringify(
    {
      agencyId,
      firstDateRange: JSON.stringify({
        days,
        endDate,
        expanded,
        stopIds: [stopId],
        startDate,
        timeRange: timeRange ?? '',
      }),
    },
    { arrayFormat: 'comma' }
  );
  return `/stops/summary?${params}`;
};

const getRouteDetailsLink = ({
  agencyId,
  expanded,
  dateRange,
  maxDate,
  routeId,
}: {
  agencyId?: string;
  expanded?: boolean;
  dateRange: RelativeDateRange;
  maxDate: Moment | string;
  routeId: string;
}) => {
  const {
    dateRangeType,
    startDate: fixedStartDate,
    endDate: fixedEndDate,
    days,
    timeRange,
  } = dateRange;
  let startDate = fixedStartDate;
  let endDate = fixedEndDate;
  if (dateRangeType !== RelativePeriod.CUSTOM) {
    const max = typeof maxDate === 'string' ? maxDate : maxDate.format();
    const { startDate: relativeStartDate, endDate: relativeEndDate } =
      getDatesForRelativePeriod(dateRangeType as RelativePeriod, max);
    startDate = relativeStartDate;
    endDate = relativeEndDate;
  }

  const params = queryString.stringify(
    {
      agencyId,
      firstDateRange: JSON.stringify({
        days,
        endDate,
        expanded,
        routeIds: [routeId],
        startDate,
        timeRange: timeRange ?? '',
      }),
    },
    { arrayFormat: 'comma' }
  );
  return `/routes/summary?${params}`;
};

const getTripDetailsLink = ({
  agencyId,
  expanded,
  dateRange,
  maxDate,
  routeId,
  tripId,
}: {
  agencyId?: string;
  expanded?: boolean;
  dateRange: RelativeDateRange;
  maxDate: Moment | string;
  routeId: string;
  tripId: string;
}) => {
  const {
    dateRangeType,
    startDate: fixedStartDate,
    endDate: fixedEndDate,
    days,
    timeRange,
  } = dateRange;
  let startDate = fixedStartDate;
  let endDate = fixedEndDate;
  if (dateRangeType !== RelativePeriod.CUSTOM) {
    const max = typeof maxDate === 'string' ? maxDate : maxDate.format();
    const { startDate: relativeStartDate, endDate: relativeEndDate } =
      getDatesForRelativePeriod(dateRangeType as RelativePeriod, max);
    startDate = relativeStartDate;
    endDate = relativeEndDate;
  }

  const params = queryString.stringify(
    {
      agencyId,
      firstDateRange: JSON.stringify({
        days,
        endDate,
        expanded,
        routeIds: [routeId],
        tripIds: [tripId],
        startDate,
        timeRange: timeRange ?? '',
      }),
    },
    { arrayFormat: 'comma' }
  );
  return `/trips/details?${params}`;
};

const getNameForPath = (path?: string) => {
  if (!path) {
    return;
  }

  let name = 'analyze';
  if (/^\/settings/.test(path)) {
    return `${name}${path}`;
  }

  switch (path) {
    case '/system':
      return `${name}/system`;
    case '/routes':
      return `${name}/all-routes`;
    case '/routes/summary':
    case '/routes/tags':
      return `${name}/single-route`;
    case '/routes/trips':
      return `${name}/single-route/all-trips`;
    case '/trips/details':
      return `${name}/single-route/single-trip`;
    case '/stops':
      return `${name}/all-stops`;
    case '/stops/summary':
      return `${name}/single-stop`;
    case '/insights/all':
      return `${name}/all-insights`;
    default:
      if (/^\/(insights|views)\/[0-9a-fA-F-]+/.test(path)) {
        return `${name}/single-insight`;
      } else {
        return undefined;
      }
  }
};

const getTitleForPath = ({
  path,
  routeName,
  stopName,
  insightName,
}: {
  path: string;
  routeName?: string;
  stopName?: string;
  insightName?: string;
}) => {
  switch (path) {
    case '/login':
      return 'Login';
    case '/signup':
      return 'Sign up';
    case '/reset':
      return 'Reset password';
    case '/system':
      return 'System';
    case '/routes':
      return 'Routes';
    case path.match(/\/routes\/(summary|trips|tags)/)?.input:
      return routeName ? `Route ${routeName}` : 'Routes';
    case '/stops':
      return 'Stops';
    case path.match(/\/stops\/summary/)?.input:
      return stopName ?? 'Stops';
    case path.match(/\/trips\/details/)?.input:
      return routeName ? `Trip details | Route ${routeName}` : 'Trip details';
    case '/insights':
    case '/insights/all':
      return 'Insights';
    case path.match(/\/(insights|views)\/[0-9a-fA-F]+/)?.input:
      return insightName ?? 'Insights';
    case path.match(/\/settings.*/)?.input:
      return 'Settings';
    default:
      return '';
  }
};

export {
  getRouteDetailsLink,
  getStopDetailsLink,
  getSystemLink,
  getTripDetailsLink,
  getNameForPath,
  getTitleForPath,
};
