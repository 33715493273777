import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Toast,
  dequeueToast,
  selectToasts,
} from '../../store/slices/layoutSlice';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

export default function ToastManager() {
  const dispatch = useDispatch();
  const toasts = useSelector(selectToasts);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState<Toast>();

  useEffect(() => {
    if (toasts.length && !toast) {
      setToast({ ...toasts[0] });
      dispatch(dequeueToast());
      setOpen(true);
    } else if (toasts.length && toast && open) {
      setOpen(false);
    }
  }, [dispatch, toasts, toast, open]);

  const handleClose = () => setOpen(false);

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={10000}
      TransitionProps={{
        onExited: () => setToast(undefined),
      }}
    >
      {toast && (
        <Alert severity={toast.severity} variant='filled'>
          {toast.message}
        </Alert>
      )}
    </Snackbar>
  );
}
