import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveInsight,
  selectInsightViewType,
  setInsightViewType,
} from '../../store/slices/layoutSlice';
import MaybeWrap from '../MaybeWrap';
import { Box, Tooltip } from '@mui/material';
import { MapOutlined, TableChartOutlined } from '@mui/icons-material';
import { isSupportedMapView } from '../../utils/insights';
import { SegmentedControl } from '../SegmentedControl';

const TooltipWrapper = ({
  children,
  title,
}: {
  children?: React.ReactNode;
  title: string;
}) => (
  <Tooltip title={title}>
    <Box component='span' lineHeight={0}>
      {children}
    </Box>
  </Tooltip>
);

export default function ViewModeToggleButton() {
  const dispatch = useDispatch();
  const activeView = useSelector(selectActiveInsight);
  const viewType = useSelector(selectInsightViewType);

  const disableMapView = !isSupportedMapView(activeView);
  let disabledTooltip =
    'Map view is only available when grouping by the entire date range';
  switch (activeView?.insightType) {
    case 'system':
      disabledTooltip = 'Map view is not available for system insights';
      break;
    case 'trips':
      disabledTooltip = 'Map view is not available for trip insights';
      break;
  }

  return (
    <MaybeWrap
      when={disableMapView}
      wrapper={<TooltipWrapper title={disabledTooltip} />}
    >
      <SegmentedControl
        value={viewType}
        onChange={(_, newViewType) =>
          newViewType &&
          dispatch(setInsightViewType(newViewType as 'map' | 'table'))
        }
        items={[
          { value: 'table', label: 'Table', Icon: <TableChartOutlined /> },
          {
            value: 'map',
            label: 'Map',
            Icon: <MapOutlined />,
            disabled: disableMapView,
          },
        ]}
      />
    </MaybeWrap>
  );
}
