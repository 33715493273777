import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid2 as Grid,
  Typography,
  TextField,
  Fade,
  Tab,
  Tabs,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { useHistory } from 'react-router-dom';
import {
  Visibility,
  VisibilityOff,
  Assignment as PlanningSelectedIcon,
  AssignmentOutlined as PlanningIcon,
  DirectionsBus as OperationsSelectedIcon,
  DirectionsBusOutlined as OperationsIcon,
  SupervisedUserCircle as ExecutiveTeamSelectedIcon,
  SupervisedUserCircleOutlined as ExecutiveTeamIcon,
  Assessment as AnalysisSelectedIcon,
  AssessmentOutlined as AnalysisIcon,
  MonetizationOn as GrantsSelectedIcon,
  MonetizationOnOutlined as GrantsIcon,
  HeadsetMic as CustomerServiceSelectedIcon,
  HeadsetMicOutlined as CustomerServiceIcon,
  Help as OtherSelectedIcon,
  HelpOutlined as OtherIcon,
} from '@mui/icons-material';
import LoadingButton from '../../components/LoadingButton';

// logo
import logo from '../../assets/hopthru.svg';

// context
import { useDispatch } from 'react-redux';
import { updateIsAuthenticated } from '../../store/slices/userSlice';
import { setPassword } from '../../store/effects/user';
import tracking from '../../utils/tracking';

import { fetchDepartments, completeSignup, postEvent } from '../../utils/api';
import queryString from 'query-string';

type Department = {
  id: string;
  created_ts: string | null;
  updated_ts: string | null;
  name: string;
};

function Signup() {
  const history = useHistory();

  // global
  const dispatch = useDispatch();

  // local
  const [activeTabId, setActiveTabId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const params = queryString.parse(history.location.search);

  const icons = {
    Planning: <PlanningIcon />,
    Operations: <OperationsIcon />,
    'Executive Team': <ExecutiveTeamIcon />,
    'Data Analysis': <AnalysisIcon />,
    'Grants / Government Affairs': <GrantsIcon />,
    'Customer Service': <CustomerServiceIcon />,
    Other: <OtherIcon />,
  };

  const selectedIcons = {
    Planning: <PlanningSelectedIcon />,
    Operations: <OperationsSelectedIcon />,
    'Executive Team': <ExecutiveTeamSelectedIcon />,
    'Data Analysis': <AnalysisSelectedIcon />,
    'Grants / Government Affairs': <GrantsSelectedIcon />,
    'Customer Service': <CustomerServiceSelectedIcon />,
    Other: <OtherSelectedIcon />,
  };

  async function fetchDepartmentsData() {
    let result = await fetchDepartments(history);
    if (result) setDepartments(result);
  }

  async function postSignupData() {
    try {
      await completeSignup(
        history,
        firstName,
        lastName,
        jobTitle,
        selectedDepartments,
        setError as any
      );
      localStorage.setItem('first_name', firstName);
      localStorage.setItem('last_name', lastName);
      tracking.event('analyze-signup-success');
      postEvent('complete_signup');
      dispatch(updateIsAuthenticated(true));
    } catch (e) {
      tracking.event('analyze-signup-failed');
    }
  }

  function toggleDepartment(id: string) {
    const selected = [...selectedDepartments];
    const index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setSelectedDepartments(selected);
  }

  useEffect(() => {
    tracking.event('analyze-signup-viewed');
  }, []);

  useEffect(() => {
    if (activeTabId === 1) {
      fetchDepartmentsData();
    }
  }, [activeTabId]);

  function onEnterPressed() {
    if (activeTabId === 0) {
      if (newPassword !== confirmNewPassword) {
        setError('Password confirmation does not match');
      } else {
        dispatch(
          setPassword(
            params.token?.toString() || '',
            newPassword,
            true,
            setIsLoading,
            setError,
            () => {
              setActiveTabId(1);
            }
          )
        );
      }
    } else {
      postSignupData();
    }
  }

  return (
    <Box overflow='hidden auto' height='100%'>
      <Box
        sx={{
          minHeight: '100vh',
          width: '100vw',
          backgroundColor: 'primary.main',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={logo} alt='Hopthru' title='Hopthru' />
        <Box sx={{ display: 'table', m: 2, height: 0 }}>
          <Tabs
            value={activeTabId}
            style={{ marginBottom: 16, width: 900 }}
            variant='fullWidth'
          >
            <Tab disabled={activeTabId !== 0} label='Create a password' />
            <Tab disabled={activeTabId !== 1} label='Set up your account' />
          </Tabs>
          <Box
            alignItems='center'
            border='solid 1px #dadce0'
            borderRadius='10px'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            maxWidth={900}
            minHeight={600}
          >
            {activeTabId === 0 && (
              <Box
                sx={{
                  width: 400,
                  height: 562,
                  py: 4,
                  px: 2.5,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box mt={2}>
                  <Typography color='secondary' variant='h2'>
                    Let&rsquo;s set your password
                  </Typography>
                </Box>
                <Box mt={7}>
                  <TextField
                    id='password'
                    value={newPassword}
                    label='Enter a password'
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        onEnterPressed();
                        ev.preventDefault();
                      }
                    }}
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      notched: false,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Show password'
                            aria-pressed={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                            size='small'
                            title='Show password'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mt={5}>
                  <TextField
                    id='confirmPassword'
                    value={confirmNewPassword}
                    label='Confirm password'
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        onEnterPressed();
                        ev.preventDefault();
                      }
                    }}
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      notched: false,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Show password'
                            aria-pressed={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                            size='small'
                            title='Show password'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    w: 1,
                    textAlign: 'center',
                    mt: 4,
                    alignItems: 'center',
                  }}
                >
                  <LoadingButton
                    disabled={newPassword.length === 0}
                    fullWidth
                    onClick={() => onEnterPressed()}
                    loading={isLoading}
                    variant='contained'
                    color='secondary'
                    sx={{ height: 50 }}
                  >
                    Next
                  </LoadingButton>
                </Box>
                <Fade in={!!error}>
                  <Typography color='error' sx={{ mt: 4, textAlign: 'center' }}>
                    {error}
                  </Typography>
                </Fade>
              </Box>
            )}
            {activeTabId === 1 && (
              <>
                <Box display='flex' flexDirection='column' py={4} px={4}>
                  <Box mt={2} mx='auto'>
                    <Typography color='secondary' variant='h2'>
                      Tell us a bit about you
                    </Typography>
                  </Box>
                  <Box mt={4}>
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <Box mt={3}>
                          <TextField
                            id='first'
                            value={firstName}
                            label='First name'
                            style={{ float: 'left', marginRight: 8 }}
                            placeholder={'First name'}
                            onChange={(e) => setFirstName(e.target.value)}
                            type='text'
                            variant='outlined'
                            fullWidth
                            InputProps={{ notched: false }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                      <Grid size={6}>
                        <Box mt={3}>
                          <TextField
                            id='last'
                            value={lastName}
                            label='Last name'
                            style={{ float: 'left' }}
                            placeholder={'Last name'}
                            onChange={(e) => setLastName(e.target.value)}
                            type='text'
                            variant='outlined'
                            fullWidth
                            InputProps={{ notched: false }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                      <Grid size={6}>
                        <Box mt={3}>
                          <TextField
                            id='role'
                            value={jobTitle}
                            label='What&rsquo;s your role?'
                            placeholder={'Job title'}
                            onChange={(e) => setJobTitle(e.target.value)}
                            type='text'
                            variant='outlined'
                            fullWidth
                            InputProps={{ notched: false }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography color='secondary' style={{ marginTop: 8 }}>
                    What department(s) do you work in? Choose one or more.
                  </Typography>
                  <Box mt={1}>
                    <Grid container spacing={2}>
                      {departments.map((dept) => {
                        let selected = selectedDepartments.includes(dept.id);
                        let icon = selected
                          ? selectedIcons[
                              dept.name as keyof typeof selectedIcons
                            ]
                          : icons[dept.name as keyof typeof icons];
                        return (
                          <Grid size={6} key={dept.id}>
                            <ToggleButton
                              value={dept.id}
                              selected={selectedDepartments.includes(dept.id)}
                              onChange={() => toggleDepartment(dept.id)}
                              sx={{
                                width: '100%',
                                borderRadius: 2.5,
                                textAlign: 'left',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {icon} <Box ml={1}>{dept.name}</Box>
                            </ToggleButton>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
                <Box mb={4} width={360}>
                  <LoadingButton
                    disabled={
                      selectedDepartments.length === 0 ||
                      !jobTitle ||
                      !firstName ||
                      !lastName
                    }
                    fullWidth
                    loading={isLoading}
                    onClick={() => onEnterPressed()}
                    variant='contained'
                    color='secondary'
                    sx={{ height: 50 }}
                  >
                    Finish
                  </LoadingButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Signup;
