import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ReportProblemOutlined';

type LoadingSpinnerProps = { showError?: boolean; message?: string };

export default function LoadingSpinner({
  showError,
  message,
}: LoadingSpinnerProps) {
  return (
    <Box
      height='100%'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      {!showError ? (
        <>
          <CircularProgress
            color='secondary'
            size={12}
            sx={{ mr: 1.5, color: '#5e6267' }}
          />
          <Typography
            color='secondary'
            sx={{
              float: 'left',
              fontSize: 12,
              color: '#5e6267',
              textDecoration: 'none',
            }}
          >
            {message ? message : 'Chart loading'}
          </Typography>
        </>
      ) : (
        <>
          <ErrorIcon
            color='error'
            sx={{ float: 'left', mr: 1.5, color: '#d0021b' }}
          />
          <Typography
            color='secondary'
            sx={{
              float: 'left',
              fontSize: 12,
              color: '#5e6267',
              textDecoration: 'none',
            }}
          >
            Error loading data
          </Typography>
        </>
      )}
    </Box>
  );
}
