import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Router } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';
import history from './utils/history';

import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import FeatureProvider from './context/FeatureProvider';
import ToastManager from './components/ToastManager';
import * as Sentry from '@sentry/react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// vite does not define a global object like webpack
window.global ||= window;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
    Sentry.globalHandlersIntegration({ onunhandledrejection: false }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_SENTRY_ENV !== 'test',
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <LayoutProvider>
      <FeatureProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <ToastManager />
            <Router history={history}>
              <App />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </FeatureProvider>
    </LayoutProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
