import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/userSlice';
import { selectFirstDateRange } from '../../store/slices/analyticsFiltersSlice';
import { selectActiveInsight } from '../../store/slices/layoutSlice';
import { api } from '../../store/slices/apiSlice';
import { getTitleForPath } from '../url';

export default function usePageTitle() {
  const location = useLocation();
  const activeInsight = useSelector(selectActiveInsight);
  const { isAuthenticated } = useSelector(selectUser);
  const firstDateRange = useSelector(selectFirstDateRange);

  const { unreadCount } = api.endpoints.getUnreadNotifications.useQueryState(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        unreadCount: data?.unread_count ?? 0,
      }),
    }
  );

  const { stopName } = api.endpoints.getStop.useQueryState(
    {
      stopId: firstDateRange?.stopIds?.[0],
    },
    {
      selectFromResult: ({ currentData }) => ({
        stopName: currentData?.stopName,
      }),
    }
  );

  const { routeName } = api.endpoints.getRouteDescription.useQueryState(
    {
      routeId: firstDateRange?.routeIds?.[0],
    },
    {
      selectFromResult: ({ currentData }) => ({
        routeName: currentData?.routeShortName,
      }),
    }
  );

  useEffect(() => {
    let title = 'Hopthru';
    const pageTitle = getTitleForPath({
      path: location.pathname,
      routeName,
      stopName,
      insightName: activeInsight?.name,
    });
    if (pageTitle) {
      title = `${pageTitle} | ${title}`;
    }

    if (isAuthenticated && unreadCount > 0) {
      title = `(${unreadCount}) ${title}`;
    }

    document.title = title;
  }, [
    isAuthenticated,
    location,
    unreadCount,
    activeInsight?.name,
    routeName,
    stopName,
  ]);
}
