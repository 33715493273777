import React, { useState } from 'react';
import {
  useGetUnreadNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
} from '../../store/slices/apiSlice';
import { Badge, Box, Button, Divider, Popover } from '@mui/material';
import { Notifications, NotificationsNone } from '@mui/icons-material';
import HeaderMenuButton from '../HeaderMenuButton';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import NotificationCard from './NotificationCard';

type NotificationButtonProps = { useV2?: boolean };

export default function NotificationButton({ useV2 }: NotificationButtonProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { data: notificationData } = useGetUnreadNotificationsQuery(undefined, {
    pollingInterval: 10000,
  });
  const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();
  const unreadCount = notificationData?.unread_count ?? 0;

  const HeaderButton = useV2 ? HeaderMenuButtonV2 : HeaderMenuButton;

  return (
    <>
      <Badge
        badgeContent={unreadCount}
        color='error'
        overlap='circular'
        invisible={unreadCount === 0}
      >
        <HeaderButton
          aria-controls='notification-menu'
          aria-haspopup='true'
          aria-label='Notifications'
          isActive={!!anchorEl}
          onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
          title='Notifications'
          className=''
          variant='dark'
        >
          {anchorEl ? (
            <Notifications fontSize='inherit' />
          ) : (
            <NotificationsNone fontSize='inherit' />
          )}
        </HeaderButton>
      </Badge>
      <Popover
        id='notification-menu'
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: 'calc(100vh - 32px)',
          },
        }}
      >
        <Box
          maxHeight='inherit'
          maxWidth={456}
          display='flex'
          flexDirection='column'
        >
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            fontSize='1rem'
            fontWeight={500}
            minWidth={400}
            px={2}
            py={1}
          >
            Notifications
            <Button
              variant='outlined'
              disabled={unreadCount === 0}
              onClick={() => markAllAsRead()}
            >
              Dismiss all
            </Button>
          </Box>
          <Divider />
          <Box height='100%' overflow='hidden auto' pt={1}>
            {unreadCount === 0 && (
              <Box
                height={500}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                You&rsquo;re all caught up!
              </Box>
            )}
            {unreadCount !== 0 && notificationData?.unread_list && (
              <Box px={1}>
                {notificationData.unread_list.map((notification) => (
                  <NotificationCard
                    notification={notification}
                    key={notification.id}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
}
