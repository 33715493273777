import defaultTheme from './default';

import { createTheme } from '@mui/material/styles';

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.375rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
};

type HopthruTheme = {
  [Key in keyof typeof defaultTheme]: (typeof defaultTheme)[Key];
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

const theme = {
  default: createTheme({
    ...defaultTheme,
    ...overrides,
  }),
};

export default theme;
