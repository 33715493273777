import React from 'react';
import { Box, IconButton } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { Notification } from '../../store/types';
import MaybeWrap from '../MaybeWrap';

type NotificationCardProps = {
  notification: Notification;
};

const LinkWrapper = ({
  children,
  href,
}: {
  children?: React.ReactNode;
  href?: string;
}) => (
  <Box
    component='a'
    href={href}
    sx={(theme) => ({
      borderRadius: theme.spacing(1.5),
      display: 'block',
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        outline: `1px solid ${theme.palette.secondary.main}`,
        textDecoration: 'underline',
      },
      '&:focus': {
        outline: `2px solid ${theme.palette.secondary.main}`,
        outlineOffset: 2,
        textDecoration: 'underline',
      },
    })}
  >
    {children}
  </Box>
);

export default function NotificationCard({
  notification,
}: NotificationCardProps) {
  const { verb, data } = notification;
  const hasLink = !!data?.link;

  return (
    <MaybeWrap when={hasLink} wrapper={<LinkWrapper href={data?.link} />}>
      <Box
        alignItems='center'
        borderRadius='10px'
        border={1}
        display='flex'
        justifyContent='space-between'
        mb={1}
        padding={1.5}
        borderColor='tertiary.main'
      >
        {verb}
        {data?.download_link && (
          <Box ml={1}>
            <IconButton
              aria-label='Download file'
              color='secondary'
              component='a'
              href={data.download_link}
              size='small'
              target='_blank'
              title='Download file'
            >
              <GetApp />
            </IconButton>
          </Box>
        )}
      </Box>
    </MaybeWrap>
  );
}
