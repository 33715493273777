import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  IconButtonProps,
  Menu,
  Typography,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandIcon from '../../assets/expand.svg?react';
import ExpandIconWhite from '../../assets/expand_white.svg?react';
import {
  selectSecondDateRange,
  updateFirstDateRange,
  updateSecondDateRange,
} from '../../store/slices/analyticsFiltersSlice';
import { persistAnalyticsFilters } from '../../store/effects/analyticsFilters';
import { useLayoutState } from '../../context/LayoutContext';

export interface HeaderMenuButtonProps extends IconButtonProps {
  isActive?: boolean;
}

const StyledHeaderMenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<HeaderMenuButtonProps>(({ theme, isActive }) => ({
  backgroundColor: theme.palette.primary.main,
  border: '1.5px solid #DADCE0',
  borderRadius: 50,
  fontSize: '2rem',
  height: theme.spacing(5.25),
  marginLeft: theme.spacing(1),
  padding: theme.spacing(1),
  width: theme.spacing(5.25),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.main,
  },
  ...(isActive && {
    backgroundColor: theme.palette.secondary.main,
    borderColor: '#202124',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
}));

export default function ExpandMenuButton() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const layoutState = useLayoutState();
  const secondDateRange = useSelector(selectSecondDateRange);

  const toggleExpandedData = () => {
    layoutState.setDataExpanded(!layoutState.dataExpanded);
    dispatch(updateFirstDateRange({ expanded: !layoutState.dataExpanded }));
    if (secondDateRange) {
      dispatch(updateSecondDateRange({ expanded: !layoutState.dataExpanded }));
    }
    dispatch(persistAnalyticsFilters());
  };

  return (
    <>
      <StyledHeaderMenuButton
        aria-controls='expand-menu'
        aria-haspopup='true'
        aria-label='Expanded data'
        color='inherit'
        id='expand_button'
        isActive={layoutState.dataExpanded}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
        title='Expanded data'
      >
        {layoutState.dataExpanded ? <ExpandIconWhite /> : <ExpandIcon />}
      </StyledHeaderMenuButton>
      <Menu
        id='expand-menu'
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          mt: '7px',
        }}
        disableAutoFocusItem
      >
        <Box display='flex'>
          <ExpandIcon aria-hidden focusable='false' style={{ margin: 8 }} />
          <div>
            <Typography fontSize={'1.125rem'}>Expanded data</Typography>
            <Typography fontSize={'0.8125rem'}>
              Fill in missing trips using averages from historical data
            </Typography>
          </div>
          <Switch
            checked={layoutState.dataExpanded}
            inputProps={{ 'aria-label': 'Toggle expanded data' }}
            onChange={toggleExpandedData}
          />
        </Box>
      </Menu>
    </>
  );
}
