import queryString from 'query-string';
import moment from 'moment-timezone';
import history from './history';
import { filterEmptyProperties } from './utils';
import { UserRole } from '../store/types';

function getAgencyFromRole(userRole?: Partial<UserRole>) {
  if (userRole && userRole.agency) {
    const {
      name,
      id,
      latitude,
      longitude,
      data_start_date,
      data_end_date,
      data_feed_days_trailing,
      agency_time_zone,
      display_distance_type,
      internal_name,
      show_offs,
      show_direction,
      show_load,
      show_pdt,
      show_trips,
      show_insights,
      show_atypical_days,
      show_maxload,
      show_total_load,
      show_activity,
      show_bprh,
      show_expand,
      use_stop_code,
      is_cleanse_only,
      swiftly_agency_key,
    } = userRole.agency;

    const agency = filterEmptyProperties({
      agencyKey: swiftly_agency_key,
      agencyName: name,
      agencyId: id,
      agencyLatitude: latitude,
      agencyLongitude: longitude,
      agencyDataStartDate: data_start_date,
      agencyDataEndDate: data_end_date,
      agencyDataFeedDaysTrailing: data_feed_days_trailing,
      agencyTimeZone: agency_time_zone ?? moment.tz.guess(),
      displayDistanceType: display_distance_type,
      internalName: internal_name,
      showOffs: show_offs,
      showDirection: show_direction,
      showLoad: show_load,
      showPDT: show_pdt,
      showTrips: show_trips,
      showInsights: show_insights,
      showAtypicalDays: show_atypical_days,
      showMaxload: show_maxload,
      showTotalLoad: show_total_load,
      showActivity: show_activity,
      showBPRH: show_bprh,
      showExpand: show_expand,
      useStopCode: use_stop_code,
      isCleanseOnly: is_cleanse_only,
    });

    return agency;
  }

  return {};
}

// Sets active user role in the following order of precedence:
// 1. Role matching the agencyId in URL params
// 2. Role from session storage
// 3. Role from local storage
// 4. Initial role in list of user roles
function getActiveUserRole(userRoles: Partial<UserRole>[] = []) {
  const params = queryString.parse(history.location.search);
  if (params && 'agencyId' in params && params.agencyId !== null) {
    const role = userRoles.find((role) => role?.agency?.id === params.agencyId);
    if (role) {
      return role;
    }
  } else if (sessionStorage.getItem('user_role') !== null) {
    return JSON.parse(sessionStorage.getItem('user_role') || '');
  } else if (localStorage.getItem('user_role') !== null) {
    return JSON.parse(localStorage.getItem('user_role') || '');
  } else if (userRoles.length > 0) {
    return userRoles[0];
  }

  return null;
}

function persistSession(userRole: string, agencyId: string) {
  sessionStorage.setItem('user_role', userRole);
  sessionStorage.setItem('agency_id', agencyId);
}

function getUserRoleFromId(agencyId: string, userRoles: UserRole[]) {
  const role = userRoles.find((userRole) => userRole.agency.id === agencyId);
  return role ?? null;
}

export {
  getAgencyFromRole,
  getActiveUserRole,
  getUserRoleFromId,
  persistSession,
};
