import React from 'react';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

// logo
import logo from '../../assets/hopthru.svg';

export default function Error() {
  return (
    <Grid
      container
      sx={{
        h: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'primary.main',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 1.5,
        }}
      >
        <Box component='img' src={logo} alt='logo' />
        <Typography variant='h3' color='white' sx={{ fontWeight: 500, ml: 2 }}>
          Hopthru
        </Typography>
      </Box>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 1,
          maxWidth: 404,
        }}
      >
        <Typography
          variant='h1'
          color='primary'
          sx={{ mb: 1, textAlign: 'center', fontSize: 148, fontWeight: 500 }}
        >
          404
        </Typography>
        <Typography
          variant='h5'
          color='primary'
          sx={{ mb: 1, textAlign: 'center' }}
        >
          Oops. Looks like the page you're looking for no longer exists
        </Typography>
        <Typography
          variant='h6'
          color='text'
          colorBrightness='secondary'
          sx={{
            mb: 1,
            textAlign: 'center',
            fontWeight: 300,
            color: 'text.hint',
          }}
        >
          But we're here to bring you back to safety
        </Typography>
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to='/'
          size='large'
          sx={{ fontSize: 22 }}
        >
          Back to Home
        </Button>
      </Paper>
    </Grid>
  );
}
