import Segment from './segment';

const event = function (eventName: string, metadata?: ObjectMap) {
  if (typeof eventName !== 'string') {
    return;
  }

  if (typeof window.Intercom === 'function') {
    window.Intercom('trackEvent', eventName, metadata);
  }

  const properties = metadata ?? undefined;
  Segment.track(eventName, { properties });
};

const tracking = { event };

export default tracking;
