import React, { useState, useRef } from 'react';
import { Menu, MenuItem, MenuItemProps } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

type SubmenuProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
  rtl?: boolean;
} & MenuItemProps;

function Submenu(props: SubmenuProps) {
  const { children, label, rtl, ...rest } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement | null>(null);

  return (
    <MenuItem
      {...rest}
      ref={ref}
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      {label}
      <ChevronRight />
      <Menu
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'top', horizontal: rtl ? 'left' : 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rtl ? 'right' : 'left',
        }}
        open={open}
        style={{ pointerEvents: 'none' }}
      >
        <div style={{ pointerEvents: 'auto' }}>{children}</div>
      </Menu>
    </MenuItem>
  );
}

export default Submenu;
